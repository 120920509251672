
import { config } from './config';


export const APP_NAME = config.appName;
export const APP_DESCRIPTION = config.appDescription;
export const APP_META_TAGS = config.metaTags;
export const  COREBOS_URL = config.server?.url;
export const USER_AVATAR = config.userAvatar;
export const IMAGE_FULL_PATH = 'fullpath';
export const IMAGEINFO_FULL_PATH = 'imageinfo.fullpath';
export const BUSINESS_PROCESS_FLOW = config.processFlowModules;
export const COMPANY_LOGO_URL = config.logoUrl;