import it from "./lang/it";
import en from "./lang/en";
import polyglotI18nProvider from 'ra-i18n-polyglot';

const i18nProvider = polyglotI18nProvider(locale =>
    locale === 'it' ? it : en,
    'en'
);

export default i18nProvider
