import * as React from 'react';
import { useEffect, useCallback, useState } from 'react';
import { useCommentStyle } from './useCommentStyle';
import { getDataFromLocalDb } from '../../utils/Helpers';
import { TABLE_AUTH } from '../../local-db';
import {
    useNotify,
    Notification,
    //useRefresh,
    useCreate,
    useUpdate,
    Create,
    SimpleForm,
    Toolbar,
    SaveButton,
    //FormDataConsumer,
} from 'react-admin';
import {
    Box,
    Button as CommentButton,
} from '@material-ui/core';
import RemoveIcon from '@material-ui/icons/Remove';
import RichTextInput from 'ra-input-rich-text';
import CircularProgress from '@material-ui/core/CircularProgress';
import { DropzoneComponent } from 'react-dropzone-component';

import { 
    Dropzone, 
    doCreate, 
    prepareFileToUpload
} from '../lib'
import "dropzone/dist/dropzone.css";
import "react-dropzone-component/styles/filepicker.css";
import LinearProgress from '@material-ui/core/LinearProgress';
import { treatSpecialChars } from '../utils';

export interface UserSelectionDialogProps {
    isOpen: boolean;
    selectedValue: any;
    onClose: (value: boolean) => void;
}

export const CommentForm = ({ projectTask, resource, setReloadTriggerID, handleCancel=null, replyingComment=null, comment, isNewRecord=false }:{ projectTask: any, resource: string, setReloadTriggerID: any, handleCancel: any, replyingComment: any, comment: any, isNewRecord: boolean }) => {

    const classes = useCommentStyle();
    const notify = useNotify();
    //const refresh = useRefresh();
    const [create] = useCreate();
    const [update] = useUpdate();
    const [isloading, setIsLoading] = useState(false);
    const [user, setUser] = useState<any>({});
    const [uploading, setUploading] = useState<boolean>(false)
    const [fileList, setFileList] = useState<any[]>([])
    const [debounceKey, setDebounceKey] = useState(0);
    //const [userList, setUserList] = useState([]);
    /* const [userSelectionList, setUserSelectionList] = useState([]);
    const [open, setOpen] = useState<boolean>(false);
    const [selectedUser, setSelectedUser] = useState<any>({}); */


    const dropzoneComponentConfig : any = {
        autoDiscover: false,
        iconFiletypes: ['jpg', 'jpeg', 'png', 'jfif', 'gif', 'pdf'],
        //showFiletypeIcon: true,
        postUrl: 'no-url',
    }

    const djsConfig = { 
        autoProcessQueue: false,
        //addRemoveLinks: true,
     }

    const eventHandlers = {
        addedfile: async (file: any) => {
          setFileList(fileList => [...fileList, file])
          return false
        },
        init: function () {
          Dropzone.autoDiscover = false;
        }
    }

    const clearDropzone = () => {
        setFileList([]);
        setDebounceKey(new Date().getTime());
    }

    useEffect(() => {
        /* getDataFromLocalDb(TABLE_DESCRIBE.tableName).then((result) => {
            setUserList(result[resource].userlist??[]);
        }); */
        getDataFromLocalDb(TABLE_AUTH.tableName).then((result) => {
            setUser(result?.user??{});
        });
    }, [resource]);

    const uploadFile = useCallback(
        async (values) => {
            const { fileToUpload, relationsIds, relProjectId, title = '' } : { fileToUpload: any, relationsIds: string, relProjectId: string, title: string }  = values;
            const fileExtension = fileToUpload.name.split('.').pop()
            if (dropzoneComponentConfig.iconFiletypes.includes(fileExtension)) {
              setUploading(true)
              const docData = await prepareFileToUpload(fileToUpload, title, relationsIds, relProjectId, user);
              return doCreate('Documents', docData).then(() => {
                Promise.resolve();
              }).catch(() => {
                // notify(error, 'error');
                return Promise.resolve();
              }).finally(() => {
                setUploading(false);
              })
            } else {
                notify('Unsupported file type', 'error');
                return Promise.resolve();
            }
        },
        [dropzoneComponentConfig.iconFiletypes, notify, user]
    )

    const removeFileFromList = (fileToRemove: any) => {
        setFileList(fileList.filter((file: any) => file.name !== fileToRemove.name))
    }

    const handleSave = useCallback(
        async (values) => {
         const title = `${projectTask?.projecttask_no}`;
         setIsLoading(true);
         if(isNewRecord){
            values.assigned_user_id = user?.id;
            values.related_to = projectTask?.id;
            values.parent_comments = replyingComment?.id??'';
            values.commentcontent = treatSpecialChars(values.commentcontent);
            create(resource, values, {
                onSuccess: async (result: any) => {
                    if(fileList.length > 0){
                        for (let index = 0; index < fileList.length; index++) {
                            const uploadFileData: any = {
                                fileToUpload: fileList[index],
                                relationsIds: `${result?.data?.id}, ${projectTask?.id}`,
                                relProjectId: projectTask?.projectid,
                                title: title,
                            }
                            await uploadFile(uploadFileData);
                        }
                    }
                    clearDropzone();
                    setIsLoading(false);
                    setReloadTriggerID(new Date().getTime());
                },
                onFailure: () => {
                    setIsLoading(false);
                    notify('Something went wrong, try again later', 'error');
                },
            });
         }else{
            const dataToSend: any = comment;
            dataToSend.commentcontent = treatSpecialChars(values.commentcontent);
            setIsLoading(true);
            update(resource, dataToSend.id, dataToSend, comment, {
                onSuccess: async (result: any) => {
                    if(fileList.length > 0){
                        for (let index = 0; index < fileList.length; index++) {
                            const uploadFileData: any = {
                                fileToUpload: fileList[index],
                                relationsIds: `${result?.data?.id}, ${projectTask?.id}`,
                                relProjectId: projectTask?.projectid,
                                title: title,
                            }
                            await uploadFile(uploadFileData);
                        }
                    }
                    clearDropzone();
                    handleCancel();
                    setIsLoading(false);
                    setReloadTriggerID(new Date().getTime());
                },
                onFailure: () => {
                    setIsLoading(false);
                    notify('Something went wrong, try again later', 'error');
                },
            }); 
         }
        },
        [projectTask?.projecttask_no, projectTask?.id, projectTask?.projectid, isNewRecord, user?.id, replyingComment?.id, create, resource, fileList, setReloadTriggerID, uploadFile, notify, comment, update, handleCancel],
    );

    const CommentToolbar = (props:any) => (
        <Toolbar {...props} style={{backgroundColor: '#fff', padding: '0', position: 'relative'}}>
            <Box mx={2} mt={-10} width={'100%'}>
               
                <Box className={classes.buttons}>
                    {handleCancel &&
                        <CommentButton
                            className={classes.cancel}
                            onClick={handleCancel}
                            color="primary"
                            style={{textTransform: 'none'}}
                        >
                            Cancel
                        </CommentButton>
                    }
                    <SaveButton
                        label={'Save'}
                        redirect={false}
                        icon = { isloading ? <CircularProgress color={isloading ? 'primary' : 'secondary' } size={24} /> : <></>}
                        disabled={props.pristine || isloading}
                        size="small"
                        style={{textTransform: 'none'}}
                        {...props}
                    />
                </Box>
            </Box>
        </Toolbar>
    );

    /* const openUserSelectionDialog = (users: any[]) => {
        Swal.fire({
            title: '<strong>HTML <u>example</u></strong>',
            icon: 'info',
            html: `${
                <List>
                    {users.map((user: any) => (
                    <ListItem button onClick={() => setSelectedUser(user)} key={user.userid}>
                        <ListItemAvatar>
                        <Avatar className={classes.avatar}>
                            <PersonIcon />
                        </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={user.username} />
                    </ListItem>
                    ))}
                </List>
            }`,
            showCloseButton: true,
            showCancelButton: true,
            focusConfirm: false,
            confirmButtonText:
              '<i class="fa fa-thumbs-up"></i> Great!',
            confirmButtonAriaLabel: 'Thumbs up, great!',
            cancelButtonText:
              '<i class="fa fa-thumbs-down"></i>',
            cancelButtonAriaLabel: 'Thumbs down'
        })   
    }; */
    

    /* const UserSelectionDialog = ({dialogProps, users} : {dialogProps: UserSelectionDialogProps, users: any[]}) => {
        const { onClose, isOpen } = dialogProps;

        const handleClose = () => {
            onClose(false);
            closeUserSelectionDialog();
        };
        
        const handleListItemClick = (value: any) => {
            onClose(value);
        };

        return (
            <Dialog onClose={handleClose} aria-labelledby="user-selection-dialog" open={isOpen}>
                <List>
                    {users.map((user: any) => (
                    <ListItem button onClick={() => handleListItemClick(user)} key={user.userid}>
                        <ListItemAvatar>
                        <Avatar className={classes.avatar}>
                            <PersonIcon />
                        </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={user.username} />
                    </ListItem>
                    ))}
                </List>
            </Dialog>
        )
    } */

    /* const handleFormValuesChange = (formDataValues: any) => {
        setTimeout(() => {
            if(formDataValues && formDataValues.commentcontent){
                let stripedText = removeTags(formDataValues.commentcontent);
                const searchStr = stripedText.split('@')[1];
                if(searchStr && searchStr.length > 2){
                    console.log(searchStr);
                    console.log(searchStr.slice(0, 2));
                    const users = userList.filter((user: any) => user.username.includes(searchStr));
                    //setUserSelectionList(users);
                    //console.log(users);
                    openUserSelectionDialog(users);
                }
            }
        }, 2000);
    } */

    /* const checkCommentTxt = (commentTxt: any) => {
        let newV = commentTxt;
        console.log(userList);
        setTimeout(() => {
            if(commentTxt){
                let stripedText = removeTags(newV);
                console.log(stripedText);
                const searchStr = stripedText.split('@')[1];
                if(searchStr && searchStr.length > 2){
                    const _window: any = window;
                    //const users: any[] = userList.filter((user: any) => user.username.includes(searchStr));
                    let users: any[] = _window?.coreBOS?.Describe[resource]?.userlist??[];
                    users = users.filter((user: any) => user.username.includes(searchStr));
                    //setUserSelectionList(users);
                    console.log(users);
                    if(users.length > 0){
                        newV = '<>'+ newV + '<span>'+ users[0].username +'</span></>';
                        console.log(newV);
                        return newV;
                    }
                }
            }
        }, 1000);
    } */

    return (
        <>
        {/* <UserSelectionDialog dialogProps={{selectedValue: selectedUser, open: open,  onClose: closeUserSelectionDialog}} users={userSelectionList} /> */}
        <Box my={4} px={3} style={{ border: '1px solid #ddd' }}>
             <Box mt={4}>
                <DropzoneComponent
                    key={debounceKey}
                    djsConfig={djsConfig}
                    config={dropzoneComponentConfig}
                    eventHandlers={eventHandlers} 
                />

                {uploading &&
                   <LinearProgress />
                }

                {fileList.length > 0 &&
                    <Box>
                    {fileList.map((file, index) => (
                        <Box display="flex" key={index}>
                            {file.name}
                            <span onClick={(e) => removeFileFromList(file)}><RemoveIcon /></span>
                        </Box>
                    ))}
                    </Box>
                }
            </Box>
            <Create
            title={projectTask?.projecttask_no}
            resource={resource}
            basePath={resource}
            component="div"
            >
                <SimpleForm initialValues={ !isNewRecord && comment ? comment : {} } save={handleSave} toolbar={<CommentToolbar />} >
                    <RichTextInput 
                        source="commentcontent" 
                        label={''}
                        validation={{ required: true }}
                        //parse={checkCommentTxt}
                        options={{
                            modules: {
                               /*  history: { // History module
                                    delay: 2000,
                                    maxStack: 500,
                                    userOnly: true
                                }, */
                                toolbar: [
                                    [{ header: [1, 2, false] }],
                                    ['bold', 'italic', 'underline'],
                                    ['code-block']
                                ],
                            },
                            theme: "snow",
                        }}
                    />

                    {/* <FormDataConsumer>
                        {({formData}) => {
                            handleFormValuesChange(formData);
                            return <></>;
                        }}
                    </FormDataConsumer> */}
                </SimpleForm>
            </Create>
            <Notification />
        </Box>
       
        </>
    )

}

export default CommentForm;