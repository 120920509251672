import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { TABLE_DESCRIBE } from './local-db';
import { loginAction } from './utils/Helpers';
import { getDataFromLocalDb } from './utils/Helpers';
import { getMenu } from './utils/lib';
import { Loading } from 'react-admin';

if (window.coreBOS === undefined) {
  window.coreBOS = {};
}

window.coreBOS.LoginEvent = new CustomEvent('coreBOSLoginEvent', {});

const ProvideAuth = ({ children, isLoading }) => {
  return isLoading ? <Loading /> : children;
}


class Auth extends Component {
  constructor(props) {
    super(props)

    this.state = {
      error: false,
      code: '',
      isLoading: true,
      user: null,
    }
  }

  static propTypes = {
    user: PropTypes.object,
    validateToken: PropTypes.func,
  }
  

  componentDidMount() {
    this.authenticate();
  }

  async authenticate (){ 
    const describeData = await getDataFromLocalDb(TABLE_DESCRIBE.tableName);
    if(!describeData){
      const userMenu = await getMenu();
      const coreBOSDescribe = [];
      for (const key in userMenu) {
          if(userMenu.hasOwnProperty(key) && userMenu[key]['2']) {
            if(userMenu[key]['1'] === 'module' && userMenu[key]['4'] !== '51' && userMenu[key]['4'] !== '64' && userMenu[key]['4'] !== '111' && userMenu[key]['4'] !== '1' && userMenu[key]['2'] && userMenu[key]['2'] !== 'Reports'){
              coreBOSDescribe.push(userMenu[key]['2']) 
            }
          }
      } 
      loginAction(coreBOSDescribe).then((describe) => {
        window.coreBOS.Describe = describe;
        window.location.reload();
      })
    }else{
        window.coreBOS.Describe = describeData;
        this.setState({isLoading: false});
    }
  }

  render() {
    
    const { children } = this.props;

    return <ProvideAuth children={children} isLoading={this.state.isLoading} />

  }

}


export default Auth;