import React from "react";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Box from "@material-ui/core/Box";
import DocumentPreview from '.';
import { useTranslate } from "react-admin";



const DocumentPreviewModal = ({ record, title, open, setOpen } : { record: any, title:string, open: boolean, setOpen:any }) => {

    const transalate = useTranslate();

    const handleClose = () => {
		setOpen(false);
	};

    return (
        <Box>
			<Dialog
				open={open}
				onClose={handleClose}
				scroll={'paper'}
				aria-labelledby="document-preview"
				aria-describedby="document-preview-modal"
				fullScreen
				maxWidth={'md'}
			>
				<DialogTitle id="document-preview">{title}</DialogTitle>
				<DialogContent dividers >
					<DocumentPreview title={title} fileUrl={record?._downloadurl} type={record?.filetype} />
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="primary">
						{transalate('Cancel')}
					</Button>
				</DialogActions>
			</Dialog>
		</Box>
    )
}

export default DocumentPreviewModal;