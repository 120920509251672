import React, { useState, useEffect } from 'react';
import { List, 
    useTranslate,
    Button,
    Responsive,
    BulkDeleteButton,
    Datagrid,
    FunctionField,
} from 'react-admin';
import Box from '@material-ui/core/Box';
import DocumentPreviewModal from './DocumentPreview/DocumentPreviewModal';
import ResponsiveList from './ResponsiveList';
import { ListActionToolbar } from './ListActionToolbar';
import { CBListFilter } from './CBListFilter';
import CustomFieldLink from './CustomFieldLink';
import cbUtils from './corebosUtils/corebosUtils';
import RowAction from './RowAction';
import { authProvider } from '../authProvider';


export const ListView = ({resource, basePath, fields, filterFields, describe, linkFields=[], labelField='', title='', filterDefaultValues = {}, relmod='', relfield='', relfieldval='', otherProps } : {resource: string, basePath: string, fields: any[], filterFields: any[], linkFields: any[], labelField: string, describe: any, title: any, filterDefaultValues: any, relmod: string, relfield: string, relfieldval: string, otherProps: any }) => {

    const translate = useTranslate();
    const [docPreviewOpen, setDocPreviewOpen] = useState<boolean>(false);
	const [ clickedItem, setClickedItem ] = useState<any>({});
    const [ modPermissions, setModPermissions ] = useState<any>(null);


    useEffect(() => {
        authProvider.getModulePermission(resource).then((result: any) => {
            setModPermissions(result);
        });
    }, [resource])
    


    const BulkActionButtons = (props: any) => (
		<BulkDeleteButton {...props} />
	);

    const MasterListAction = (props: any) => {
		let params = '';
		if(filterDefaultValues){
			params = `relatedModule=${relmod}&relatedField=${ filterDefaultValues && relfieldval ? relfieldval : 'relations'}&relatedFieldValue=${relfieldval}`
		}
		return (
			<Box display="flex">
				<ListActionToolbar {...props} params={params} />
			</Box>
		)
	}

    const handleDocPreviewModalOpen = (record = null) => {
        setClickedItem(record);
        setDocPreviewOpen(true);
    };


    return (
        <>
            <DocumentPreviewModal record={clickedItem} title={clickedItem?.notes_title} open={docPreviewOpen} setOpen={setDocPreviewOpen} />
            <List
                {...otherProps}
                basePath={basePath}
                resource={resource}
                title={ title ? title : <></>}
                perPage={10}
                bulkActionButtons={<BulkActionButtons />}
                filterDefaultValues={filterDefaultValues}
                filters={<CBListFilter fields={filterFields} describe={describe} disabledfield={relfield} disabledfieldStyle={{display: 'none'}} />}
                actions={<MasterListAction modPermission={modPermissions} />}
                component="div"
                hasList={modPermissions?.read}
                hasCreate={modPermissions?.create}
                hasEdit={modPermissions?.update}
                hasShow={modPermissions?.read}
            >
                <Responsive 
                    small={<ResponsiveList resource={resource} fields={filterFields} linkFields={linkFields} linkField={labelField} describe={describe} modPermission={modPermissions} />}
                    medium={
                        <Datagrid>
                            {
                                fields.map((field: any, index: number) => {
                                    if(field.uitype === '28'){
                                        return (
                                            <FunctionField label={translate('File')} render={(record: any) => {
                                                return (
                                                    <>
                                                        { record.filetype.split('/')[0] === 'image' 
                                                            ? <img 
                                                                    key={index}
                                                                    src={record?._downloadurl} 
                                                                    alt={record?.notes_title} 
                                                                    style={{maxWidth: '40%', margin: 'auto', cursor: 'pointer' }} 
                                                                    onClick={ () => handleDocPreviewModalOpen(record)}
                                                                />
                                                            : 	<Button 
                                                                    key={index}
                                                                    color='primary' 
                                                                    label={record?.notes_title} 
                                                                    onClick={ () => handleDocPreviewModalOpen(record)}
                                                                />
                                                        }
                                                    </>
                                                    
                                                )}
                                            } />
                                        )
                                    } else {
                                        return linkFields.includes(field.name) 
                                            ?   <FunctionField label={field.label} render={(record: any) => {
                                                    return (
                                                        <CustomFieldLink {...otherProps} linkField={field.name} key={index} record={record} basePath={basePath} resource={resource} /> 
                                                )}
                                                }/>
                                            : cbUtils.field2DisplayElement(field, resource, describe);
                                    }
                                })
                            }
                            <RowAction {...otherProps} modPermission={modPermissions} basePath={basePath} resource={resource} />
                        </Datagrid>
                    }
                />
            </List>
        </>
    )
}
