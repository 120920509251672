import React, { useState } from 'react';
import { useListContext, Button } from 'react-admin';
import { Card, CardContent, CardHeader, Box, Typography, Divider } from '@material-ui/core';
import cbUtils from './corebosUtils/corebosUtils';
import CustomFieldLink from './CustomFieldLink';
import RowAction from './RowAction';
import DocumentPreviewModal from './DocumentPreview/DocumentPreviewModal';



const ResponsiveList = (props: {describe: any, resource: string, fields: any[], linkFields: any, linkField: string, modPermission: any}) => {

    const { ids, data, basePath } = useListContext();
    const [docPreviewOpen, setDocPreviewOpen] = useState<boolean>(false);
	const [ clickedItem, setClickedItem ] = useState<any>({});



    const handleDocPreviewModalOpen = (record = null) => {
        setClickedItem(record);
        setDocPreviewOpen(true);
    };

    return (
        <Box p={3}>
            <DocumentPreviewModal record={clickedItem} title={clickedItem?.notes_title} open={docPreviewOpen} setOpen={setDocPreviewOpen} />
            {ids.map(id =>
                <Card key={id} style={{ padding: '10px', margin: '15px auto', border: '1px solid #ddd' }}>
                    <CardHeader 
                        title={<CustomFieldLink resource={props.resource} record={data[id]} linkField={props.linkField} />} 
                        action={<RowAction basePath={basePath} record={data[id]} resource={props.resource} modPermission={props.modPermission} />}
                    />
                    <Divider />
                    <CardContent>
                        { props.fields.map((field: any, index: number) => {
                           if(field.uitype === '28'){
                               const aRecord: any = data[id];
                            return(
                                <Box my={1}>
                                    { data[id].filetype.split('/')[0] === 'image' 
                                        ? <img 
                                                key={index}
                                                src={aRecord?._downloadurl} 
                                                alt={aRecord?.notes_title} 
                                                style={{width: '100%', margin: 'auto', cursor: 'pointer' }} 
                                                onClick={ () => handleDocPreviewModalOpen(aRecord)}
                                            />
                                        : 	<Button 
                                                key={index}
                                                color='primary' 
                                                label={data[id]?.notes_title} 
                                                onClick={ () => handleDocPreviewModalOpen(aRecord)}
                                            />
                                    }
                                </Box>
                            )
                        } else {
                            return (
                                <Box my={1}>
                                    <Typography variant="subtitle2"> {field.label} </Typography>
                                    { props.linkFields.includes(field.name) 
                                        ? <CustomFieldLink linkField={field.name} resource={props.resource} record={data[id]} /> 
                                        : cbUtils.field2DisplayRecord(field, data[id], props.resource, props.describe)}
                                </Box>
                            )
                        }
                        })}
                    </CardContent>
                </Card>
            )}
        </Box>
    );
};

export default ResponsiveList;