import * as React from 'react';
import { forwardRef, useState } from 'react';
import { UserMenu, MenuItemLink, useTranslate, useGetIdentity, LoadingIndicator, SidebarToggleButton } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Toolbar from '@material-ui/core/Toolbar';
import SettingsIcon from '@material-ui/icons/Settings';
import { makeStyles } from '@material-ui/core/styles';
import { APP_NAME } from '../constant';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined';
import { useSelector } from "react-redux";
import { AppState } from "../types";
import AppMenu from './Menu';
import clsx from 'clsx';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { COMPANY_LOGO_URL } from '../constant';



const useStyles = makeStyles({
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    appTitle: {
        fontWeight: 'bold',
    },
    spacer: {
        flex: 1,
    },
    list: {
        width: 250,
    },
    fullList: {
        width: '100%',
    },
    logoBox: {
        position: 'absolute',
        top: '35px',
        width: '10%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    },
});


const CustomUserMenu = forwardRef<any, any>((props, ref) => {
    const translate = useTranslate();
    return (
        <UserMenu {...props}>
            <MenuItemLink
                ref={ref}
                to="/"
                primaryText={translate('ra.page.dashboard')}
                leftIcon={<DashboardOutlinedIcon />}
                onClick={props.onClick}
                sidebarIsOpen={true}
            />
            <MenuItemLink
                ref={ref}
                to={`/Contacts/${props.user?.id}/show`}
                primaryText={translate('translations.profile')}
                leftIcon={<PersonOutlineOutlinedIcon />}
                onClick={props.onClick}
                sidebarIsOpen={true}
            />
            <MenuItemLink
                ref={ref}
                to="/configuration"
                primaryText={translate('translations.configuration')}
                leftIcon={<SettingsIcon />}
                onClick={props.onClick}
                sidebarIsOpen
            />
            <Divider />
        </UserMenu>
    )
});

const CustomAppBar = (props: any) => {
    const classes = useStyles();
    const { identity } = useGetIdentity();
    const menuPosition = useSelector((state: AppState) => state.menuPosition);
    const isMedium = useMediaQuery((theme: any) => theme.breakpoints.up('md'));
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const { isLoadingMenu } = props;
    const menuItemsList = useSelector((state: AppState) => state.appMenu);

    const toggleDrawer = (anchor:any, open: any) => (event:any) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setIsOpen(!isOpen);
    };

    
    return (
        <AppBar color='secondary' elevation={1}>
            <Toolbar style={{padding: '8px 20px', minHeight: '64px', maxHeight: '64px'}}>
                { !isMedium
                  ? <>
                    <IconButton onClick={toggleDrawer('left', true)}  color="inherit" className={props.className} >
                        <MenuIcon />
                    </IconButton>
                    <Box>
                        { COMPANY_LOGO_URL 
                            ?   <Box style={{width: '30%'}}>
                                    <img src={COMPANY_LOGO_URL} alt={'Company Logo'} style={{width: '100%'}} />
                                </Box>
                            :   <Typography variant="h6" color="inherit" className={classes.appTitle}>{ APP_NAME }</Typography> 
                        }
                    </Box>
                    <span className={classes.spacer} />
                    <LoadingIndicator />
                    <CustomUserMenu {...props} user={identity?.user} />
                    <SwipeableDrawer
                        anchor={'left'}
                        open={isOpen}
                        onClose={toggleDrawer(menuPosition, false)}
                        onOpen={toggleDrawer(menuPosition, true)}
                    >
                        <Toolbar />
                        <div
                            className={clsx(classes.list, {
                                [classes.fullList]: menuPosition === 'right',
                            })}
                            role="presentation"
                        >
                            <Box mx={2} mt={-5} mb={3}>
                                { COMPANY_LOGO_URL 
                                    ?   <Box style={{width: '75%'}} mx={'auto'}>
                                            <img src={COMPANY_LOGO_URL} alt={'Company Logo'} style={{width: '100%'}} />
                                        </Box>
                                    :   <Typography variant="h6" color="inherit" className={classes.appTitle}>{ APP_NAME }</Typography> 
                                }
                            </Box>
                            <AppMenu menuItemsList={menuItemsList} isLoadingMenu={isLoadingMenu} />
                        </div>
                    </SwipeableDrawer>
                    </>
                  : <>
                        { menuItemsList && menuItemsList.length > 0 && 
                            <>
                                { menuPosition === 'top' && 
                                <Box display={'flex'} style={{width: '100%'}}>
                                    <Box my={'auto'} style={{width: '12%'}}>
                                        { COMPANY_LOGO_URL 
                                            ?   <img src={COMPANY_LOGO_URL} alt={'Company Logo'} style={{width: '100%'}} />
                                            :   <Typography variant="h6" color="inherit" className={classes.appTitle}>{ APP_NAME }</Typography> 
                                        }
                                    </Box>
                                    <span className={classes.spacer} />
                                    <Box style={{width: 'auto'}} mx={'auto'} my={'auto'}>
                                        <AppMenu menuItemsList={menuItemsList} isLoadingMenu={isLoadingMenu} />
                                    </Box>
                                    <span className={classes.spacer} />
                                    <Box display={'flex'} style={{width: 'auto'}}>
                                        <LoadingIndicator />
                                        <Box my={'auto'}>
                                            <CustomUserMenu {...props} user={identity?.user} />
                                        </Box>
                                    </Box>
                                </Box>
                                }
                                { menuPosition === 'left' && 
                                    <>
                                        <SidebarToggleButton />
                                        <Typography
                                            variant="h6"
                                            color="inherit"
                                            className={classes.title}
                                            id="react-admin-title"
                                        />
                                       <Box className={classes.logoBox}>
                                            { COMPANY_LOGO_URL 
                                                ?   <img src={COMPANY_LOGO_URL} alt={'Company Logo'} style={{width: '100%'}} />
                                                :   <Typography variant="h6" color="inherit" className={classes.appTitle}>{ APP_NAME }</Typography> 
                                            }
                                        </Box>
                                        <span className={classes.spacer} />
                                        <LoadingIndicator />
                                        <CustomUserMenu {...props} user={identity?.user} />
                                    </>
                                }
                                { menuPosition === 'right' && 
                                    <Box display={'flex'} width={'100%'}>
                                        <Box display={'flex'} my={'auto'} style={{width: 'auto'}}>
                                            <CustomUserMenu {...props} user={identity?.user} />
                                            <LoadingIndicator />
                                        </Box>
                                        
                                        <Box className={classes.logoBox}>
                                            { COMPANY_LOGO_URL 
                                                ?   <img src={COMPANY_LOGO_URL} alt={'Company Logo'} style={{width: '100%'}} />
                                                :   <Typography variant="h6" color="inherit" className={classes.appTitle}>{ APP_NAME }</Typography> 
                                            }
                                        </Box>
                                        <span className={classes.spacer} />
                                        <Box my={'auto'} style={{width: 'auto'}}>
                                            <Typography
                                                variant="h6"
                                                color="inherit"
                                                className={classes.title}
                                                id="react-admin-title"
                                                style={{flex: 'inherit'}}
                                            />
                                            <SidebarToggleButton />
                                        </Box>
                                    </Box>
                                }
                            </>
                        }
                    </>
                    }
            </Toolbar>
        </AppBar>
    );
};

export default CustomAppBar;