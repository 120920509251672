import React from 'react';
import {Iframe} from './styled';
import Box from "@material-ui/core/Box";
import useMediaQuery from '@material-ui/core/useMediaQuery';

const DocumentPreview = ({ type = '', fileUrl, title } : { type: string, fileUrl: any, title: string }) => {

    const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));

    if(type.split('/')[0] === 'image'){
        return (
            <Box style={{width: isMobile ? '100%' : '50%'}} mx={'auto'}>
                <img src={fileUrl} alt={title} style={{width: '100%', height: '100%', margin: 'auto'}} />
            </Box>
        )
    }


    return <Iframe src={fileUrl} />
}

export default DocumentPreview;