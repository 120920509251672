import React, { useState, useEffect } from 'react';
import { useTranslate, useRedirect } from 'react-admin';
import { getDataFromLocalDb } from '../../utils/Helpers';
import { TABLE_DESCRIBE } from '../../local-db';
import { 
	Box,
	Grid,
	Card,
	TextField,
	Typography,
} from '@material-ui/core';
import queryString from 'query-string';
import { getListColumns } from '../../utils/lib';
import { doQuery, getRelatedModules } from '../../utils/lib';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { ListView } from '../ListView';
import RelatedModCard from '../../component/RelatedModCard'



export const CbListGuesser = props => {
	let resource = props.resource;
	const [describe, setDescribe] = useState({});
	const [fields, setFields] = useState([]);
	const [label, setLabel] = useState('');
	const [labelField, 	setLabelField] = useState('');
	const [linkFields, setLinkfields] = useState([]);
	const [filterFields, setFilterFields] = useState([]);
	const modPermission = props?.options?.permission[0]?.permissions??null;
	let defaultSearchquery = {};
	const { relmod, relfield, relfieldval, reltype, parentid } = queryString.parse(props?.location?.search);
	if(relmod && relfieldval && reltype){
		defaultSearchquery[relfield] = relfieldval;
		defaultSearchquery['relatedModule'] = resource;
		defaultSearchquery['moduleRelationType'] = reltype;
		defaultSearchquery['joinCondition'] = 'AND';
	} else if(parentid) {
		defaultSearchquery['id'] = parentid;
	} else {
		defaultSearchquery = {};
	}

	const translate = useTranslate();
	const [ records, setRecords ] = useState([]);
	const redirect = useRedirect();
	const [relatedModules, setRelatedModules] = useState([]);

	
	useEffect(() => {
		doQuery(`SELECT * FROM  ${resource}`).then((result) => {
			setRecords(result);
		});
	}, [resource])
	
	
	useEffect(() => {
		getDataFromLocalDb(TABLE_DESCRIBE.tableName).then((result) => {
			return result;
		}).then(describeData => {
			setDescribe(describeData);
			setLabel(describeData[resource]?.label);
			setLinkfields(describeData[resource]?.filterFields?.linkfields ?? []);
			let labelfields = describeData[resource].labelFields.split(',');
			setLabelField(labelfields[0]);

			let ffields = describeData[resource]?.filterFields?.fields ?? [];
			const objFilterFields = [];
			let modFields = [];
			for (let f = 0; f<ffields.length; f++) {
				const field = describeData[resource]?.fields.find((element) => {
					return element.name === ffields[f];
				});
				if(field){
					modFields.push(field);
					objFilterFields.push(field);
				}
			}
			
			setFilterFields(objFilterFields);
			getRelatedModules(resource).then((rMods) => { 
				const relatedMods = [];
				let describeRelatedMod = {};
				if(rMods && rMods.blocks){
					const rModules = Object.values(rMods.blocks);
					rModules.forEach((rMod) => {
						if(describeData[rMod.loadfrom]){
							describeRelatedMod = describeData[rMod.loadfrom]
							describeRelatedMod.relatedfield = rMod.relatedfield;
							describeRelatedMod.moduleRelationType = rMod.relatedfield ? '1:N' : 'N:N';
						} else {
							describeRelatedMod = {};
							describeRelatedMod.name = rMod.loadfrom;
							describeRelatedMod.label = rMod.label;
							describeRelatedMod.relatedfield = rMod.relatedfield;
							describeRelatedMod.moduleRelationType = rMod.relatedfield ? '1:N' : 'N:N';
						}
						relatedMods.push(describeRelatedMod);
					});
				}

				setRelatedModules(relatedMods);
			})

			if(relmod && relfield && relfieldval && reltype){
				setLinkfields(describeData[relmod]?.filterFields?.linkfields ?? []);
				let labelfields = describeData[relmod].labelFields.split(',');
				setLabelField(labelfields[0]);

				getListColumns(resource).then((result) => { 
					let relatedListColumnNames = [];
					if(result[relmod] && result[relmod].ListFieldsName){
						for (const key in result[relmod].ListFieldsName) {
							if (Object.hasOwnProperty.call(result[relmod].ListFieldsName, key)) {
								relatedListColumnNames.push(result[relmod].ListFieldsName[key]);
							}
						}
						// eslint-disable-next-line array-callback-return
						let relatedFields = modFields.filter((field) => {
							if(relatedListColumnNames.includes(field.name)) return field;
						})
						setFields(relatedFields);
						setFilterFields(relatedFields);
					} else {
						const relModFilterFields = describeData[relmod]?.filterFields?.fields ?? [];
						const relModFields = describeData[relmod]?.fields ?? [];
						const relModFilters = [];
						// eslint-disable-next-line array-callback-return
						let relfields = relModFields.filter((field) => {
							if(relModFilterFields.includes(field.name)) {
								relModFilters.push(field);
								return field;
							};
						})
						if(relfields.length > 0){
							setFields(relfields);
							setFilterFields(relfields);
						} else {
							setFields(modFields);
							setFilterFields(modFields);
						}
					}
				}).catch(() => {
					console.log(translate('translations.somethingWentWrong'));
					const relModFilterFields = describeData[relmod]?.filterFields?.fields ?? [];
						const relModFields = describeData[relmod]?.fields ?? [];
						const relModFilters = [];
						// eslint-disable-next-line array-callback-return
						let relfields = relModFields.filter((field) => {
							if(relModFilterFields.includes(field.name)) {
								relModFilters.push(field);
								return field;
							};
						})
						if(relfields.length > 0){
							setFields(relfields);
							setFilterFields(relfields);
						} else {
							setFields([]);
							setFilterFields([]);
						}
				})
			} else {
				setFields(modFields);
			}
		}).catch(() => {
			console.log(translate('translations.somethingWentWrong'));
		})
	}, [relfield, relfieldval, relmod, reltype, resource, translate])


	const handleSearchValueChange = (newRecord) => {
		redirect(`/${resource}?parentid=${newRecord.id}`);
	}

	const handleRelatedModule = (rModule, pid) => {
		redirect(`/${resource}?parentid=${pid}&relmod=${rModule.name}&relfield=${rModule.relatedfield}&relfieldval=${pid}&reltype=${rModule.moduleRelationType}`);
	}

	const handleDefaultList = () => {
		redirect(`/${resource}`);
	}


	return (
		<>
		{!modPermission 
			? 	<Box justifyContent="center" alignContent="center" alignItems="center"> 
					<h2>ACCESS DENIED</h2>
				</Box>
			: 	<>
					<Box style={{width: '75%'}} mx={'auto'} my={3}>
						<Autocomplete
							id="searchBox"
							options={records}
							getOptionLabel={(option) => option[describe[resource].labelFields.split(',')[0]??'']}
							fullWidth
							renderInput={(params) => <TextField {...params} label={translate('Search for') + ` ${resource}` } variant="outlined" />}
							onChange={(e, aRecord) => handleSearchValueChange(aRecord)}
						/>
					</Box>
					
					<>
					<Box mb={2}>
					{ parentid && relatedModules && relatedModules.length > 0  &&
						<Grid container spacing={0} style={{marginTop: '20px'}}>
							{ relatedModules && relatedModules.length > 0 && 
								<Grid item xs={6} md={2}>
									<Card 
										onClick={() => handleDefaultList()} 
										elevation={4} 
										className={'relatedModCard'}
										>
										<Typography variant="subtitle2"> {translate('View All')} </Typography>
									</Card>
								</Grid>
							}
							{
								relatedModules.map((relatedMod) => {
									return (
										<Grid item xs={6} md={2}>
											<RelatedModCard parentMod={resource} parentId={parentid || relfieldval} relatedMod={relatedMod} handleRelatedModule={handleRelatedModule}  />
										</Grid>
									)
								})	
							}
						</Grid>

					}
					</Box>
					<ListView 
						resource={ relmod ?? resource} 
						basePath={relmod ? `/${relmod}` : `${resource}`} 
						fields={fields} 
						filterFields={filterFields} 
						linkFields={ linkFields }
						labelField={ labelField }
						describe={describe}
						title={label}
						filterDefaultValues={ defaultSearchquery }
						relmod={relmod}
						relfield={ relfield }
						relfieldval={relfieldval}
						otherProps={{}}
					/>

					</>
				</>
		}
		</>
	)
};
