import React, { cloneElement } from 'react';
import { ExportButton, sanitizeListRestProps, TopToolbar, Button } from 'react-admin';
import { Link } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';

export const ListActionToolbar = (props: any) => {
    const { resource, total, currentSort, maxResults, filters, displayedFilters, filterValues, basePath, modPermission, showFilter, ...rest } = props;

    return (
        <TopToolbar my={2} display="flex" justifyContent="end" {...props} {...sanitizeListRestProps(rest)}>
            {filters && cloneElement(filters, { resource, showFilter, displayedFilters, filterValues, context: 'button' }) }
            <ExportButton 
                //label="" 
                //variant="text" 
                disabled={total === 0}
                resource={resource}
                sort={currentSort}
                filterValues={filterValues}
                maxResults={maxResults}
                size="small"
            />
            {modPermission && modPermission.create && 
                <Button
                    variant="contained"
                    color="primary"
                    component={Link}
                    to={`/${props.resource}/create?${props?.params}`}
                    label="Create"
                    title="Create"
                >
                <AddIcon />
              </Button>
            }
        </TopToolbar>
    )
}