import { useInput, BooleanInput, BooleanField } from 'react-admin';
import { clearTable, getDataFromLocalDB, saveDataToLocalDB, TABLE_DESCRIBE, TABLE_PERMISSIONS, TABLE_LIST_VIEWS } from '../local-db';
import { getModDescribe, doInvoke } from './lib';
import DropzoneComponent from 'react-dropzone-component'
import * as DropZone from 'dropzone';
import {
    ImageField,
    UrlField,
} from 'react-admin';
import { IconSettings, Icon } from '@salesforce/design-system-react';

const convertPdf2Base64 = (document: any) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(document);
    return new Promise((resolve, reject) => {
      fileReader.onload = () => resolve(fileReader.result)
      fileReader.onerror = (error) => reject(error);
    })
}

export const Dropzone = DropZone;
export const DropzoneComp = DropzoneComponent;


const getDataFromDB = async (tableName: string) => {
    const data = await getDataFromLocalDB(tableName);
	if(tableName === 'permissions' ){
		return data && data.length ? data : null;
	}
	return data && data[0] ? data[0] : null;
}

export const formatSearchObject = (field:any, searchText:any)  => {
    if (!searchText) {
        return;
    }
    let srch:any = {};
    srch[field] = searchText;
    return srch;
}

export const FormattedBooleanInput = (props:any) => {
    let input = useInput(props);
    const isChecked = {checked: false};

    if (Number(input.input.value)) {
        isChecked.checked = true;
    }
    return (<BooleanInput {...props} options={isChecked}/>);
};

export const FormattedBooleanField = (props:any) => {
    props.record[props.source] = (props.record[props.source] === '1');

    return (
        <div>
            <div style={{width: '100%'}}>
                <label className="MuiFormLabel-root">{props.label}</label>
            </div>
            <BooleanField {...props} />
        </div>
    );
};

export const getDataFromLocalDb = async (tableName: string) => {
	const data = await getDataFromDB(tableName);
	return data;
}

export const loginAction = async (coreBOSDescribe: string[]) => {
    //await clearTable(TABLE_AUTH.tableName);
	await clearTable(TABLE_PERMISSIONS.tableName);
	await clearTable(TABLE_DESCRIBE.tableName);
    await clearTable(TABLE_LIST_VIEWS.tableName);
    //window.dispatchEvent(window.coreBOS.LoginEvent); 
    //loginActions();
    let permissions: any[] = [];
    let Describe: any = {};
    let ListViews: any = {};

    return getModDescribe(coreBOSDescribe.join(',')).then((descinfo: any) => {
            for (let key in descinfo) {
                Describe[key] = descinfo[key];
                let modulePermissions = {
                    module: key,
                    permissions: {
                        create: descinfo[key].createable,
                        delete: descinfo[key].deleteable,
                        update: descinfo[key].updateable,
                        list: descinfo[key].retrieveable,
                    }
                };
                permissions.push(modulePermissions);
            }
            return permissions;
    }).then(async (result: any) => {
        await saveDataToLocalDB(TABLE_PERMISSIONS.tableName, result, true);
        let AssignedUserList = [];
        let ViewsByModule: any[] = [];
        for (let mod = 0; mod < coreBOSDescribe.length; mod++) {
            if (Describe[coreBOSDescribe[mod]]) {
                AssignedUserList.push(
                    doInvoke('getAssignedUserList', { module: coreBOSDescribe[mod] }, 'GET').then((users: any) => {
                        Describe[coreBOSDescribe[mod]].userlist = JSON.parse(users);
                    })
                );
                ViewsByModule.push(
                    doInvoke("getViewsByModule", { module: coreBOSDescribe[mod] }, "GET").then((data) => {
                        ListViews[coreBOSDescribe[mod]] = data;
                    })
                );
            }
        }

        return Promise.all(AssignedUserList).then( () => {
            return saveDataToLocalDB(TABLE_DESCRIBE.tableName, Describe, false);
        }).then(async () => {
            await Promise.all(ViewsByModule);
            return await saveDataToLocalDB(TABLE_LIST_VIEWS.tableName, ListViews, false);
        }).then(() => {
            return Promise.resolve(Describe);
        })
    });
}

export const getModuleTabsWithFields = async (moduleName: string) => {
    const fields: any[] = [];
    const describe = await getDataFromDB(TABLE_DESCRIBE.tableName);
    if(describe == null) {
        return[];
    }

    if (!describe[moduleName]) {
        return [];
    }

    describe[moduleName].fields.forEach((e: any) => {
        if (e.block ) {
            fields.push({name: e.block.blockname, fields: [], tab: e.block.blocklabel});
        }
    });

    let tabs = new Map<any[], any>(fields.map((item:any) => [item['name'], item]));
    describe[moduleName].fields.forEach((e: any) => {
        if (e.block) {
            tabs.forEach((tab: any) => {
                if (tab.name === e.block.blockname) {
                    tab.fields.push(e);
                }
            });
        }
    });

    return tabs;
};

export const prepareMenu = (menuMap: any) => {
    if(!menuMap) return [];
    const menu: any = {};
    const menuItems: any[] = [];
    const moduleList: any[] = [];

    for (const key in menuMap) {
        if(menuMap.hasOwnProperty(key)) {
            if(!menu.hasOwnProperty(key)) {
                if(menuMap[key]["4"] === '0'){ // Menu tier (Menu heading)
                    menu[key] = {
                        menuId: menuMap[key]['0'],
                        type: 'menu',
                        name: menuMap[key]['2'],
                        label: menuMap[key]['3'], 
                        parentMenu: menuMap[key]['4'],
                        menuItemSequence: menuMap[key]['5'],
                        menuItemVisibility: menuMap[key]['6'],
                        permittedIds: menuMap[key]['7'],
                        icon: menuMap[key]['8'] ?? null,
                    };
                } else {
                    if(menuMap[key]["1"] === 'module' || menuMap[key]["1"] === 'menu' || menuMap[key]["1"] === 'url'){
                        moduleList.push({
                            menuId: menuMap[key]['0'],
                            type: menuMap[key]["1"],
                            name: menuMap[key]['2'],
                            label: menuMap[key]['3'], 
                            parentMenu: menuMap[key]['4'],
                            menuItemSequence: menuMap[key]['5'],
                            menuItemVisibility: menuMap[key]['6'],
                            permittedIds: menuMap[key]['7'],
                            icon: menuMap[key]['8'] ?? null,
                        })
                    }
                }
            } 
        }
    }

    for (const key in menu) { // 1st Level
        menuItems.push(
            {
                menuId: menu[key].menuId,
                type: menu[key].type,
                name: menu[key].name,
                label: menu[key].label, 
                parentMenu: menu[key].parentMenu,
                menuItemSequence: menu[key].menuItemSequence,
                menuItemVisibility: menu[key].menuItemVisibility,
                permittedIds: menu[key].permittedIds,
                icon: menu[key].icon,
                menuItems: moduleList.filter(mod => mod.parentMenu === menu[key].menuId)
            }
        );
    }

    for (const menuItem of menuItems) { // 2nd Level
        for (const key in menuItem.menuItems) {
            menuItem.menuItems[key] = {
                menuId: menuItem.menuItems[key].menuId,
                type: menuItem.menuItems[key].type,
                name: menuItem.menuItems[key].name,
                label: menuItem.menuItems[key].label, 
                parentMenu: menuItem.menuItems[key].parentMenu,
                menuItemSequence: menuItem.menuItems[key].menuItemSequence,
                menuItemVisibility: menuItem.menuItems[key]['6'],
                permittedIds: menuItem.menuItems[key].permittedIds,
                icon: menuItem.menuItems[key].icon,
                menuItems: moduleList.filter(mod => mod.parentMenu === menuItem.menuItems[key].menuId)
            }
        }
    }

    return menuItems;
}

export const prepareFileToUpload = async (fileToUpload: any, title: string, relationsIds: any, relProjectId: any, assigned_user_id: string) => {

    const fileExtension = fileToUpload.name.split('.').pop();
    const base64document = (fileExtension === 'pdf') ? await convertPdf2Base64(fileToUpload) : fileToUpload.dataURL
        
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0');
    const yyyy = today.getFullYear();
    const fileNameFormat = `${title}_${yyyy}-${mm}-${dd}_${fileToUpload.name}`;

    const fileParams = {
        name: fileNameFormat,
        size: fileToUpload.size,
        type: fileToUpload.type,
        content: base64document,
    };


    const docData = {
        notes_title: `${fileParams.name}`,
        filename: fileParams,
        filetype: fileToUpload.type,
        filesize: fileToUpload.size,
        filelocationtype: 'I',
        filestatus: 1,
        relations: relationsIds,
        reltoproject: relProjectId,
        assigned_user_id: assigned_user_id,
    };

    return docData;

}

export const handleFileDisplay = (file: any) => {

    if(file.filetype === 'image/png' || file.filetype === 'image/jpg' || file.filetype === 'image/jpeg'){
        return (
            <ImageField
                key={file.id}
                label={''}
                source="_downloadurl"
                record={file}
            />
        );
    }else{
        return (
            <UrlField source="_downloadurl" record={file}  label={''} target="_blank" />
        );
    }

}

export const convertFileToBase64 = (file:any) =>
new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;

    reader.readAsDataURL(file.rawFile);
});

export const ModMenuIcon = ({menu, moduleName, IconContainerStyle, IconStyle} : {menu: any[], moduleName: string, IconContainerStyle: any, IconStyle: any}) => {
    
    const foundMenuItem: any = menu.filter((menuItem: any) => menuItem.name === moduleName)[0];
    if(!foundMenuItem) {
        return <></>
    }

    return(
        <IconSettings iconPath="/icons" style={IconContainerStyle}>
            <div className={`${foundMenuItem?.icon?.containerClass}`}>
                <Icon
                    category={foundMenuItem?.icon?.library}
                    name={foundMenuItem?.icon?.icon}
                    className={`${foundMenuItem?.icon.class}`}
                    size="small"
                    style={IconStyle}
                />
            </div>
        </IconSettings>
    )
}



