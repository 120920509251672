import React from 'react';
import { RichTextField, TextField, EmailField, DateField, NumberField, UrlField, BooleanField, ImageField, SelectField, ReferenceField, DateInput, DateTimeInput, NumberInput, ReferenceInput, SelectInput, SelectArrayInput, BooleanInput, ImageInput, TextInput, AutocompleteInput, required } from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import * as cbconn from 'corebos-ws-lib/WSClientm';


function formatSearchObject(module, searchText) {
	if(!searchText){
		return;
	}
	let srch = {};
	srch['cblistsearch_'+module] = searchText;
	return srch;
}

const FormattedBooleanField = props => {
	if(props.record){
		props.record[props.source] = (props.record[props.source]==='1');
	}
	return (<BooleanField {...props} />);
};

const FormattedBooleanInput = props => {
	if(props.record){
		props.record[props.source] = Number(props.record[props.source]);
	}
	return (<BooleanInput {...props} />);
};

const corebosUtils = {
	field2DisplayRecord: (field, record, module, describe = null, referToMod = '', referenceCustomLink='') => {
		let userlist = []
		switch (Number(field.uitype)) {
			case 21: // TextBox small
				return <TextField key={field.name} label={field.label} source={field.name} fullWidth />;
			case 19: // TextBox big
				return <RichTextField key={field.name} label={field.label} fullWidth source={field.name} record={record} />;
			case 5: // Date
				return <DateField key={field.name} label={field.label} fullWidth source={field.name} record={record} />;
			case 50: // DateTime
				return <DateField key={field.name} label={field.label} fullWidth source={field.name} record={record} showTime />;
			case 7: // Number
				return <NumberField key={field.name} label={field.label} fullWidth source={field.name} record={record} />;
			case 9: // Percentage
				return <NumberField key={field.name} label={field.label} fullWidth source={field.name} record={record} options={{ style: 'percent' }} />;
			case 71: // Currency
				return <NumberField key={field.name} label={field.label} fullWidth source={field.name} record={record} options={{ style: 'currency', currency: 'EUR' }} />;
			case 10: // Module Relation
				if(!describe){
					return <></>;
				}
				let refMod = '';
				if(referToMod){
					refMod = referToMod;
				} else {
					refMod = field.type.refersTo[0] ?? '';
				}
				if(!describe[refMod]){
					return <></>;
				}
				let eidfield = describe[refMod].labelFields.split(',');
				return <ReferenceField key={field.name} label={field.label} fullWidth source={field.name} record={record} reference={refMod} link={ referenceCustomLink ? () => referenceCustomLink : 'show' } sortBy={refMod+'.'+eidfield[0]} >
						<TextField key={'ref'+field.name} source={eidfield[0]} />
					</ReferenceField>;
			case 101: // User Relation
			case 53: // User Relation: Assigned To
				userlist = describe[module]?.userlist ?? [];
				return (
					<SelectField
						key={field.name}
						label={field.label}
						source={field.name}
						record={record}
						choices={userlist}
						optionText="username"
						optionValue="userid"
						fullWidth
					/>
				);
			case 52: // User Relation: Created and Modified by
				userlist = describe[module]?.userlist ?? [];
				return <SelectField key={field.name} label={field.label} source={field.name} record={record} fullWidth choices={userlist} optionText="username" optionValue="userid" />;
			case 13: // Email
				return <EmailField key={field.name} label={field.label} source={field.name} record={record} fullWidth style={{textDecoration: 'none'}} />;
			case 17: // URL
				return <UrlField key={field.name} label={field.label} source={field.name} record={record} fullWidth />;
			case 56: // Checkbox
				return <FormattedBooleanField key={field.name} label={field.label} fullWidth source={field.name} record={record} />;
			case 28: // Image
				return <ImageField key={field.name} label={field.label} source={field.name} record={record} />;
			case 15: // SelectWithRole,
			case 16: // Select,
			case 1613: // SelectModules,
			case 1024: // SelectRoles,
			case 33: // SelectMultiple,
			case 3313: // SelectModulesMultiple,
				return <SelectField key={field.name} label={field.label} source={field.name} record={record} fullWidth choices={field.type.picklistValues} optionText="value" optionValue="label" />;
			case 1:
			case 11: // Phone
			case 14: // Time
			case 85: // Skype
			case 4:  // mod_alert_arr.AutoGenerated,
			default:
				return <TextField key={field.name} label={field.label} source={field.name} record={record} fullWidth />;
		}
	},
	field2DisplayElement: (field, module, describe = null, referToMod = '', referenceCustomLink='') => {
		let userlist = []
		switch (Number(field.uitype)) {
			case 21: // TextBox small
				return <TextField key={field.name} label={field.label} source={field.name} fullWidth />;
			case 19: // TextBox big
				return <RichTextField key={field.name} label={field.label} fullWidth source={field.name} />;
			case 5: // Date
				return <DateField key={field.name} label={field.label} fullWidth source={field.name} />;
			case 50: // DateTime
				return <DateField key={field.name} label={field.label} fullWidth source={field.name} showTime />;
			case 7: // Number
				return <NumberField key={field.name} label={field.label} fullWidth source={field.name} />;
			case 9: // Percentage
				return <NumberField key={field.name} label={field.label} fullWidth source={field.name} options={{ style: 'percent' }} />;
			case 71: // Currency
				return <NumberField key={field.name} label={field.label} fullWidth source={field.name} options={{ style: 'currency', currency: 'EUR' }} />;
			case 10: // Module Relation
				if(!describe){
					return <></>;
				}
				let refMod = '';
				if(referToMod){
					refMod = referToMod;
				} else {
					refMod = field.type.refersTo[0] ?? '';
				}
				if(!describe[refMod]){
					return <></>;
				}
				let eidfield = describe[refMod].labelFields.split(',');
				return <ReferenceField key={field.name} label={field.label} fullWidth source={field.name} reference={refMod} link={ referenceCustomLink ? () => referenceCustomLink : 'show' } sortBy={refMod+'.'+eidfield[0]} >
						<TextField key={'ref'+field.name} source={eidfield[0]} />
					</ReferenceField>;
			case 101: // User Relation
			case 53: // User Relation: Assigned To
				userlist = describe[module]?.userlist ?? [];
				return (
					<SelectField
						key={field.name}
						label={field.label}
						source={field.name}
						choices={userlist}
						optionText="username"
						optionValue="userid"
						fullWidth
					/>
				);
			case 52: // User Relation: Created and Modified by
				userlist = describe[module]?.userlist ?? [];
				return <SelectField key={field.name} label={field.label} source={field.name} fullWidth choices={userlist} optionText="username" optionValue="userid" />;
			case 13: // Email
				return <EmailField key={field.name} label={field.label} source={field.name} fullWidth style={{textDecoration: 'none'}} />;
			case 17: // URL
				return <UrlField key={field.name} label={field.label} source={field.name} fullWidth />;
			case 56: // Checkbox
				return <FormattedBooleanField key={field.name} label={field.label} fullWidth source={field.name} />;
			case 28: // Image
				return <ImageField key={field.name} label={field.label} source={field.name} />;
			case 15: // SelectWithRole,
			case 16: // Select,
			case 1613: // SelectModules,
			case 1024: // SelectRoles,
			case 33: // SelectMultiple,
			case 3313: // SelectModulesMultiple,
				return <SelectField key={field.name} label={field.label} source={field.name} fullWidth choices={field.type.picklistValues} optionText="value" optionValue="label" />;
			case 1:
			case 11: // Phone
			case 14: // Time
			case 85: // Skype
			case 4:  // mod_alert_arr.AutoGenerated,
			default:
				return <TextField key={field.name} label={field.label} source={field.name} fullWidth />;
		}
	},
	field2InputElement: (field, module, styles={}, describe = null, autogeneratedPlaceholder='', referToMod='') => {
		let userlist = [];
		if (field.editable === false && field.uitype === 4) {
			return (
				<TextInput
					variant="outlined"
					key={field.name}
					label={field.label}
					source={field.name}
					readonly
					value={autogeneratedPlaceholder}
				/>
			);
		}
	
		if (field.editable===false) {
			return null;
		}
		const isMandatory = field.mandatory ? required() : null;
		switch (Number(field.uitype)) {
			case 21: // TextBox small
				return <TextInput variant="outlined" fullWidth={styles?.fullWidth}  key={field.name} label={field.label} source={field.name} validate={isMandatory} defaultValue={field?.default} />;
			case 19: // TextBox big
				return <RichTextInput key={field?.name} label={field.label} source={field.name} multiline validate={isMandatory} defaultValue={field?.default}/>;
			case 5: // Date
				return <DateInput variant="outlined" fullWidth={styles?.fullWidth} key={field.name} label={field.label} source={field.name} validate={isMandatory} defaultValue={field?.default} />;
			case 50: // DateTime
				return <DateTimeInput variant="outlined" fullWidth={styles?.fullWidth} key={field.name} label={field.label} source={field.name} validate={isMandatory} defaultValue={field?.default} />;
			case 7: // Number
			case 9: // Percentage
			case 71: // Currency
				return <NumberInput variant="outlined" fullWidth={styles?.fullWidth} key={field.name} label={field.label} source={field.name} validate={isMandatory} />;
			case 10: // Module Relation
				if(!describe){
					return <></>;
				}
				let refMod = '';
				if(referToMod){
					refMod = referToMod;
				} else {
					refMod = field.type.refersTo[0] ?? '';
				}
				if(!describe[refMod]){
					return <></>;
				}
				let eidfield = describe[refMod].labelFields.split(',');
				return <ReferenceInput variant="outlined" fullWidth={styles?.fullWidth} key={field.name} label={field.label} source={field.name} reference={refMod} filterToQuery={searchText => formatSearchObject(refMod, searchText)} validate={isMandatory} defaultValue={field?.default} >
							<AutocompleteInput key={'ref'+field.name} optionText={eidfield[0]} />
						</ReferenceInput>;
			case 52: // User Relation: Created and Modified by
			case 70: // Created and Modified Time
				return null;
			case 53: // User Relation: Assigned To
				userlist = describe[module]?.userlist ?? [];
				return <SelectInput variant="outlined" fullWidth={styles?.fullWidth} key={field.name} label={field.label} source={field.name} choices={userlist} optionText="username" optionValue="userid" allowEmpty defaultValue={field?.default} />;
			case 101: // User Relation
				userlist = describe[module]?.userlist ?? [];
				return <SelectInput variant="outlined" fullWidth={styles?.fullWidth} key={field.name} label={field.label} source={field.name} choices={userlist} optionText="username" optionValue="userid" allowEmpty defaultValue={field?.default} />;
			case 56: // Checkbox
				return <FormattedBooleanInput variant="outlined" key={field.name} label={field.label} source={field.name} />;
			case 28: // Image
				return <ImageInput key={field.name} label={field.label} source={field.name} accept="image/*" >
						<ImageField key={'ref'+field.name} source={field.name} />
					</ImageInput>;
			case 15: // SelectWithRole,
			case 16: // Select,
			case 1613: // SelectModules,
			case 1024: // SelectRoles,
				return <SelectInput variant="outlined" fullWidth={styles?.fullWidth} key={field.name} label={field.label} source={field.name} choices={field.type.picklistValues} optionText="value" optionValue="label" validate={isMandatory} allowEmpty defaultValue={field?.type.defaultValue} />;
			case 33: // SelectMultiple,
			case 3313: // SelectModulesMultiple,
				return <SelectArrayInput variant="outlined" fullWidth={styles?.fullWidth} key={field.name} label={field.label} source={field.name} choices={field.type.picklistValues} optionText="value" optionValue="label" validate={isMandatory} />;
			case 13: // Email
				return <TextInput variant="outlined" fullWidth={styles?.fullWidth} key={field.name} label={field.label} source={field.name} type="email" validate={isMandatory} defaultValue={field?.default} />;
			case 17: // URL
				return <TextInput variant="outlined" fullWidth={styles?.fullWidth} key={field.name} label={field.label} source={field.name} type="url" validate={isMandatory} defaultValue={field?.default} />;
			case 1:
			case 11: // Phone
			case 14: // Time
			case 85: // Skype
			case 4:  // mod_alert_arr.AutoGenerated,
			default:
				return <TextInput variant="outlined" fullWidth={styles?.fullWidth} key={field.name} label={field.label} source={field.name} validate={isMandatory} defaultValue={field?.default} />;
		}
	},

	remoteValidate : async (module, values) => {
		const data = await cbconn.doValidateInformation(values.id, module, values)
		.catch(function (error) {
			return error;
		});
		let errors = {};
		if(data && typeof data === 'object'){
			for (let [key, value] of Object.entries(data)) {
				errors[key] = value[0]
			}
		}

		return errors??null;
	},

	dateParser : v => {
		const regexp = /(\d{4})-(\d{2})-(\d{2})/
		let date = new Date(v);
	
		let match = regexp.exec(date.toISOString());
		if (match === null) return;
		let year = match[1];
		let month = match[2];
		let day = match[3];
	
		const d = [year, month, day].join("-");
		return d;
	},

	loadModuleFields : async (module) => {
		const modFields = await cbconn.doDescribe(module);
		return modFields;
	},

	cbValidateInformation: async (module, values) => {
		const result = await cbconn.doValidateInformation('', module, values);
		return result;
	},

	cbRetrieve: async (id) => {
		const result = await cbconn.doRetrieve(id);
		return result;
	},

	cbUpdate: async (module, data) => {
		const result = await cbconn.doUpdate(module, data);
		return result;
	}
};

export default corebosUtils;
