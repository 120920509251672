import * as React from 'react';
import Box from "@material-ui/core/Box/Box";
import Grid from '@material-ui/core/Grid';
import {
    Button,
} from 'react-admin';


export const CommentAttachment = ({fileList, handleDocPreviewModalOpen} : { fileList: any[], handleDocPreviewModalOpen: any}) => {


    return (
        <Box>
            <Grid container spacing={2}>
                {fileList.map((file, index) => (
                   <Grid item xs={12} md={file.filetype.split('/')[0] === 'image' ? 4 : 12} key={index}>
                        <Box style={{width: '100%'}}>   
                            { file.filetype.split('/')[0] === 'image' 
                                ?   <img 
                                        key={file.id}
                                        src={file._downloadurl} 
                                        alt={file.notes_title} 
                                        style={{width: '100%', height: '100%', margin: 'auto'}} 
                                        onClick={ () => handleDocPreviewModalOpen(file)}
                                    />
                                :   <Button 
                                        key={file.id}
                                        color='primary' 
                                        label={file.notes_title} 
                                        onClick={ () => handleDocPreviewModalOpen(file)}
                                    />
                            }
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </Box>
    )

}