import * as React from 'react';
import { ReactElement, ReactNode } from 'react';
import { useSelector } from 'react-redux';
import {
    List,
    MenuItem,
    ListItemIcon,
    Typography,
    Collapse,
    Tooltip,
    Box,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExpandLess from '@material-ui/icons/ExpandLess';
import { ReduxState } from 'react-admin';
import { AppState } from "../types";
import useMediaQuery from '@material-ui/core/useMediaQuery';


const useStyles = makeStyles(theme => ({
    icon: { minWidth: theme.spacing(5) },
    sidebarIsOpen: {
        '& a': {
            transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
            paddingLeft: theme.spacing(4),
        },
    },
    sidebarIsClosed: {
        '& a': {
            transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
            paddingLeft: theme.spacing(2),
        },
    },
    topMenuDropdown: {
        position: 'absolute',
        background: '#f9f9f9',
        minWidth: '160px',
        boxShadow: '0px 8px 16px 0px rgba(0,0,0,0.2)',
        padding: '6px 4px',
        zIndex: 1,
    }
}));

interface Props {
    dense: boolean;
    handleToggle: () => void;
    icon: ReactElement;
    isOpen: boolean;
    name: string;
    children: ReactNode;
}

const SubMenu = (props: Props) => {
    
    const { handleToggle, isOpen, name, icon, children, dense } = props;
    const classes = useStyles();

    const sidebarIsOpen = useSelector<ReduxState, boolean>(
        state => state.admin.ui.sidebarOpen
    );
    const menuPosition = useSelector((state: AppState) => state.menuPosition);
    const isMedium = useMediaQuery((theme: any) => theme.breakpoints.up('md'));

    const header = (
        <MenuItem dense={dense} button onClick={handleToggle} title={name}>
            <ListItemIcon className={classes.icon}>
                {isOpen ? <ExpandLess /> : icon }
            </ListItemIcon>
            <Typography variant="inherit" color="textSecondary">
                {name}
            </Typography>
        </MenuItem>
    );

    return (
        <Box>
            {sidebarIsOpen || isOpen ? (
                header
            ) : (
                <Tooltip title={name} placement="right">
                    {header}
                </Tooltip>
            )}
            <Collapse 
                in={isOpen} 
                timeout="auto" 
                unmountOnExit
                className={ isMedium && menuPosition === 'top' ? classes.topMenuDropdown : '' }
            >
                    <List
                        dense={dense}
                        component="div"
                        disablePadding
                        className={
                            sidebarIsOpen
                                ? classes.sidebarIsOpen
                                : classes.sidebarIsClosed
                        }
                    >
                        {children}
                    </List>
            </Collapse>
        </Box>
    );
};

export default SubMenu;