import React, { useState, useEffect} from 'react';
import { Create, TabbedForm, FormTab, TabbedFormTabs } from 'react-admin';
import cbUtils from '../corebosUtils/corebosUtils';
import { getDataFromLocalDb } from '../../utils/Helpers';
import { TABLE_DESCRIBE, TABLE_AUTH } from '../../local-db';
import { parse } from 'query-string';
import { 
	Box, 
	Grid, 
} from '@material-ui/core';
import { DocumentUpload } from '../DocumentUpload';
import { useLocation} from "react-router";
import queryString from 'query-string';


export const CbCreateTabGuesser = props => {
	const module = props.resource;
	const [describe, setDescribe] = useState({});
	const [blocks, setBlocks] = useState([]);
	const [user, setUser] = useState({});
	const location = useLocation();
	const urlQueryString = queryString.parse(location.search);
	
	const { relatedModule, relatedField, relatedFieldValue } = parse(props.location.search);
	const defaultValue = {};
	let redirectUrl = '';
	if(relatedModule && relatedField && relatedFieldValue){
		defaultValue[relatedField] = relatedFieldValue;
		redirectUrl = `/${relatedModule}/${relatedFieldValue}/show?showrelmod=1&relmod=${module}`;
	} else {
		redirectUrl = `show`;
	}

	for (const key in urlQueryString) {
		if(typeof key === 'string'){
			defaultValue[key] = urlQueryString[key];
		}
	}


	useEffect(() => {
		getDataFromLocalDb(TABLE_AUTH.tableName).then((result) => {
            setUser(result?.user??{});
        });
		getDataFromLocalDb(TABLE_DESCRIBE.tableName).then((result) => {
			setDescribe(result);
			let bfields = [];
			let mfields = result[module]?.fields ?? [];
			for (let f = 0; f<mfields.length; f++) {
				if (!mfields[f].block) {
					continue;
				}
				let bidx = bfields.findIndex((element) => element.id === mfields[f].block.blockid);
				if (bidx===-1) {
					bfields.push({
						id: mfields[f].block.blockid,
						sequence: mfields[f].block.blocksequence,
						label: mfields[f].block.blocklabel,
						name: mfields[f].block.blockname,
						fields: []
					});
					bidx = bfields.findIndex((element) => element.id === mfields[f].block.blockid);
				}
				bfields[bidx].fields.push(mfields[f]);
			}

			setBlocks(bfields);
		});
	}, [module])

	return (
		<Box px={0.5} >
			<Grid container spacing={1}>
				<Grid item xs={12} md={12}>
					<Box className='raPageContent'>
					{ module === 'Documents'
			? <DocumentUpload afterUpload={null} uploadResource={module} assigned_user_id={user?.id} relationsIds={''} relProjectId={''} />
			: <Create
					{...props}
				>
				<TabbedForm initialValues={defaultValue} syncWithLocation={false} redirect={redirectUrl} tabs={<TabbedFormTabs variant="scrollable" scrollButtons="auto" {...props} />} {...props}>
					{
						blocks.map((block, bidx) => {
							return (
								<FormTab key={'fbrefblk'+bidx} label={block.name}>
									<Box style={{width: '100%'}} py={3}>
										<Grid container spacing={2} >
											{
												block.fields.map((field, idx) => {
													const cbField = cbUtils.field2InputElement(field, module, {fullWidth: true}, describe);
													return cbField ? <Grid key={idx} item xs={12} md={6}> {cbField} </Grid> : <></>;
												})
											}
										</Grid>
									</Box>
								</FormTab>
							)
						})
					}
				</TabbedForm>
			</Create>
		}
					</Box>
				</Grid>
			</Grid>
		</Box>
	)
};
