import * as React from 'react';
import CustomFieldLink from './CustomFieldLink';
import cbUtils from './corebosUtils/corebosUtils';
import { Box, Card, CardContent, Typography } from '@material-ui/core';
import { Button } from 'react-admin';

const CbRecordDetail = ({record, fields, describe, linkfields = [], resource, handleDocPreviewModalOpen } : {record: any, fields: any[], describe: any, linkfields: any[], resource: string, handleDocPreviewModalOpen: any}) => {

    return (
       <Card style={{ marginBottom: '15px', border: '1px solid #eee' }}>
			<CardContent>
				{
					fields.map((field, index) => {
						if(field.uitype === '28'){
						 	return (
								<>
									{ record.filetype.split('/')[0] === 'image' 
										? 	<Box style={{width: '100%', margin: '3px auto'}}>
												<img 
													key={index}
													src={record?._downloadurl} 
													alt={record?.notes_title} 
													style={{width: '100%', margin: 'auto', cursor: 'pointer' }} 
													onClick={ () => handleDocPreviewModalOpen(record)}
												/>
											</Box>
										: 	<Box style={{display: 'flex'}}>
												<Typography variant="subtitle2"> {field.label} </Typography>
												<span style={{margin: '-1px 5px auto 2px'}}>{':'}</span>
												<Button 
													key={index}
													color='primary' 
													label={record?.notes_title} 
													onClick={ () => handleDocPreviewModalOpen(record)}
													style={{marginTop: '-4px'}}
												/>
											</Box>
									}
								</>
						 	)
					 } else {
						 return (
							<Box style={{display: 'flex'}}>
								<Typography variant="subtitle2"> {field.label} </Typography>
								<span style={{margin: 'auto 5px auto 2px'}}>{':'}</span>
								{linkfields.includes(field.name) ? <CustomFieldLink resource={resource} record={record} linkField={field.name} key={index} /> : cbUtils.field2DisplayRecord(field, record, resource, describe)}
							</Box>
						 )
					 }
					})
				}
			</CardContent>
	   </Card>
    )
}

export default CbRecordDetail;