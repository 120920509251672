import React, { useState, useEffect, useCallback } from 'react';
import { Edit, TabbedForm, FormTab, Toolbar, SaveButton, DeleteButton, useNotify, useUpdate, useRefresh, TabbedFormTabs } from 'react-admin';
import cbUtils from '../corebosUtils/corebosUtils';
import { getDataFromLocalDb } from '../../utils/Helpers';
import { TABLE_DESCRIBE } from '../../local-db';
import { 
	CircularProgress,
	Box, 
	Grid, 
} from '@material-ui/core';
import Title from '../Title';
import { useParams } from 'react-router-dom';
import { PFPushAlong } from '../PFPushAlong';
import { BUSINESS_PROCESS_FLOW } from '../../constant';
import { useLocation} from "react-router";
import queryString from 'query-string';

export const CbEditTabGuesser = props => {
	let resource = props.resource;
	const [describe, setDescribe] = useState({});
	const [blocks, setBlocks] = useState([]);
	const modPermission = props?.options?.permission[0]?.permissions??{};
	const [isLoading, setIsLoading] = useState(false);
	const notify = useNotify();
	const [ update ] = useUpdate();
	const refresh = useRefresh();
	const [ labelField, setLabelField ] = useState(props.label??'');
	const { id } = useParams();
	const location = useLocation();
	const urlQueryString = queryString.parse(location.search);
	const defaultValue = {};

	for (const key in urlQueryString) {
		if(typeof key === 'string'){
			defaultValue[key] = urlQueryString[key];
		}
	}

	const getProcessFlow = (field) => {
		const pf = BUSINESS_PROCESS_FLOW.find((element) => element.field === field);
		return pf;
	};

	useEffect(() => {
		getDataFromLocalDb(TABLE_DESCRIBE.tableName).then((result) => {
			setDescribe(result);
			let bfields = [];
			let mfields = result[resource]?.fields ?? [];
			for (let f = 0; f<mfields.length; f++) {
				if (!mfields[f].block) {
					continue;
				}
				let bidx = bfields.findIndex((element) => element.id === mfields[f].block.blockid);
				if (bidx===-1) {
					bfields.push({
						id: mfields[f].block.blockid,
						sequence: mfields[f].block.blocksequence,
						label: mfields[f].block.blocklabel,
						name: mfields[f].block.blockname,
						fields: []
					});
					bidx = bfields.findIndex((element) => element.id === mfields[f].block.blockid);
				}
				bfields[bidx].fields.push(mfields[f]);
			}

			setBlocks(bfields);
			if(!props.label){
				const labelFields = result[resource]?.labelFields;
				if(labelFields){
					let aLabelField = labelFields.split(',');
					aLabelField = aLabelField && aLabelField[0] ? aLabelField[0] : '';
					setLabelField(aLabelField);
				}
			}
		});
	}, [props.label, resource])

	const saveRecord = useCallback(
		async (values) => {
			setIsLoading(true);
			update(resource, values?.id, values, {}, {
				onSuccess: () => {
					notify('Data saved successfully', 'success');
					setIsLoading(false);
					refresh();
				},
				onFailure: () => {
					setIsLoading(false);
					notify('something went wrong', 'error');
				},
			});
		},
		[resource, refresh, notify, update],
	);

	const CustomToolbar = (props) => {
		const { basePath, record, resource } = props;
		return (
			<Toolbar {...props}>
				{modPermission && modPermission.update && 
					<SaveButton
						label="Save"
						icon = { isLoading ? <CircularProgress color={isLoading ? 'primary' : 'secondary' } size={24} /> : <></>}
						disabled={isLoading}
						//submitOnEnter={true}
						//redirect={true}
						size="small"
						{...props}
					/>
				}
				{modPermission && modPermission.delete && 
					<DeleteButton
						basePath={basePath}
						record={record}
						resource={resource}
						size="small"
						undoable={false}
						style={{marginLeft: '20px'}}
					/>
				}
			</Toolbar>
		);
	}
	
	return (
		<Box px={0.5} >
			<Grid container spacing={1}>
				<Grid item xs={12} md={12}>
					<Box className='raPageContent'>
						<Edit 
							{...props}
							title={ <Title resource={resource} label={props.label} showTitle={props.showTitle} record={props.record} linkField={labelField} /> }
						>
							<TabbedForm save={saveRecord} toolbar={<CustomToolbar />} tabs={<TabbedFormTabs variant="scrollable" scrollButtons="auto" />} >
								{
									blocks.map((block, bidx) => {
										return (
											<FormTab key={'fbrefblk'+bidx} label={block.name}>
												<Box style={{width: '100%'}} py={3}>
													<Grid container spacing={2} >
														{
															block.fields.map((field, idx) => {
																const cbField = cbUtils.field2InputElement(field, resource, {fullWidth: true}, describe);
																const processFlow = getProcessFlow(field.name);
																return cbField 
																	? <Grid key={idx} item xs={12} md={6}> 
																		{ processFlow ? <PFPushAlong pfid={processFlow.pfId} ctxid={id} isDetailView={false}/> : cbField } 
																	</Grid> 
																	: <></>;
															})
														}
													</Grid>
												</Box>
											</FormTab>
										)
									})
								}
							</TabbedForm>
						</Edit>
					</Box>
				</Grid>
			</Grid>
		</Box>
	)
};
