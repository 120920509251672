import React from 'react';
import { Admin, adminReducer, Resource } from 'react-admin';

//import dataProvider from 'react-admin-corebos';
import dataProvider from './corebosServerProviderTest';
import Login from './auth/Login';
import { authProvider } from './authProvider';
import CustomLayout from './layout/Layout';
import i18nProvider from './component/i18n/i18nProvider';
import { CbListGuesser } from './component/corebosGuessers/cbListGuesser';
import { CbCreateTabGuesser } from './component/corebosGuessers/cbCreateTabGuesser';
import { CbEditTabGuesser } from './component/corebosGuessers/cbEditTabGuesser';
import { CbShowTabGuesser } from './component/corebosGuessers/cbShowTabGuesser';

import Dashboard from './dashboard/Dashboard';
import {TaskList} from './tasks/TaskList';
import { TaskShow } from './tasks/TaskShow';
import * as cbconn from 'corebos-ws-lib/WSClientm';
import { COREBOS_URL, APP_NAME, APP_META_TAGS } from './constant';
import { SEO } from './component/SEO';
import { getDataFromLocalDb } from './utils/Helpers';
import { TABLE_AUTH } from './local-db';
import { themeReducer, menuPositionReducer, appMenuReducer } from './app-reducers/appReducer';
import customRoutes from './routes';
import { darkTheme, lightTheme } from './layout/themes';
import { AppState } from './types';
import {
    createTheme,
	responsiveFontSizes,
	ThemeProvider,
} from '@material-ui/core/styles';
import { useSelector, Provider } from 'react-redux';
import {createStore, combineReducers} from 'redux';
import { createHashHistory } from 'history';
import { connectRouter } from 'connected-react-router';



const AppWrapper = () => {

	const history = createHashHistory();
	const rootReducer = combineReducers({
		admin: adminReducer,
		router: connectRouter(history),
		theme: themeReducer, 
		menuPosition: menuPositionReducer,
		appMenu: appMenuReducer 
	});
	const store = createStore(rootReducer);
  
	return (
	  <Provider store={store}> 
		<App history={history} /> 
	  </Provider>
	)
}

const App = ({history}: {history:any}) => {

	cbconn.setURL(COREBOS_URL);
	
	window.addEventListener('coreBOSSessionExpiredEvent', function (e) {
		authProvider.logout().then(() => {
			window.location.reload();
		});
	}, false);

	const theme = useSelector((state: AppState) => {
        const userTheme = state.theme ?? localStorage.getItem('theme');
        let appTheme: any = userTheme && userTheme === 'dark' ? darkTheme : lightTheme;
        appTheme = createTheme(appTheme);
        appTheme = responsiveFontSizes(appTheme);
        return appTheme;
    }); 


	const fetchResources = async (permissions: any[]) => {

		const authUser: any = await getDataFromLocalDb(TABLE_AUTH.tableName);
		const loggedInData: any = authUser?.logindata ?? null;
		if (loggedInData && loggedInData.userId) {
			cbconn.setSession({ sessionName: loggedInData.sessionName, userId: loggedInData.id });
			const res: any = await cbconn.doListTypes();
			const resourceList: any[] = [];
			for (const key in res) {
				if (Object.hasOwnProperty.call(res, key)) {
					resourceList.push(res[key]);
				}
			}
			const resources: any[] = resourceList.map((item: any) => {
				return <Resource
					name={item.name}
					list={ item.name === 'ProjectTask' ? TaskList : CbListGuesser}
					show={ item.name === 'ProjectTask' ? TaskShow : CbShowTabGuesser}
					create={CbCreateTabGuesser}
					edit={CbEditTabGuesser}
					options={{ label: item.name, show: true, permission: permissions?.filter(permission => permission.module === item.name) }} />;
			});
			return resources;
		} else {
			window.location.href = '#/login';
			return [];
		}
	}


	return (
		<ThemeProvider theme={theme}>
			<SEO title={APP_NAME} description='' meta={APP_META_TAGS} />
			<Admin
				title={APP_NAME}
				loginPage={Login}
				dataProvider={dataProvider}
				authProvider={authProvider}
				i18nProvider={i18nProvider} 
				layout={CustomLayout}
				dashboard={Dashboard}
				customRoutes={customRoutes}
				theme={theme}
				history={history}
				disableTelemetry // Disable react-admin applications send an anonymous request on mount to a telemetry server operated by marmelab.
			>
			
			{ permissions => fetchResources(permissions)}
			</Admin>
		</ThemeProvider>
	)
}

export default AppWrapper;
