import React, { useEffect, useState } from 'react';
import {
    List,
    ListProps,
    ExportButton,
    TopToolbar,
    Loading,
    Button,
    useTranslate,
} from 'react-admin';
import { CBListFilter } from '../component/CBListFilter';
import { TaskListContent } from './TaskListContent';
import corebosUtils from '../component/corebosUtils/corebosUtils';
import { useLocation} from "react-router";
import queryString from 'query-string';
import { makeStyles } from '@material-ui/core/styles';
import { getDataFromLocalDb } from '../utils/Helpers';
import { TABLE_DESCRIBE } from '../local-db';
import { CbListGuesser } from '../component/corebosGuessers/cbListGuesser';
import {Link} from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';

export const TaskList = (props: ListProps) => {
    const resource:any = props?.resource;

    const [describe, setDescribe] = useState(null);

    const location = useLocation();
    let { filter } = queryString.parse(location.search) ? JSON.parse(JSON.stringify(queryString.parse(location.search))) : null;
    filter = filter ? JSON.parse(filter) : filter;
    const [project, setProject] = useState<any>(null);

    //const [taskFields, setTaskFields] = useState([]);
    const [taskFilterFields, setTaskFilterFields] = useState([]);
    const [isLoadigProject, setIsLoadingProject] = useState(true);

    useEffect(() => {
        /* eslint-disable react-hooks/exhaustive-deps */
        getDataFromLocalDb(TABLE_DESCRIBE.tableName).then((result) => {
			setDescribe(result);
		});

        if(filter && filter.projectid){
            corebosUtils.loadModuleFields(resource).then((modDescribe) => {
                let filterFields = modDescribe?.filterFields?.fields??[];
                filterFields = filterFields.filter((field:string) => field !== 'projectid');
                for(let index = 0; index < filterFields.length; index++) {
                    const filterField = modDescribe?.fields.filter((field:any) => field.name === filterFields[index])[0];
                    if(filterField){
                        filterFields[index] = JSON.parse(JSON.stringify(filterField));
                    }
                }
                setTaskFilterFields(filterFields);
                //setTaskFields(modDescribe?.fields??[]);
            });

            setIsLoadingProject(true);
            corebosUtils.cbRetrieve(filter.projectid).then((result) => {
                setProject(result);
                setIsLoadingProject(false);
            })
        }else{
            setIsLoadingProject(false);
        }
        
    }, [resource]);


    return (
        <>
        {!isLoadigProject && !project 
            ? <CbListGuesser {...props} />
            : <>
                {taskFilterFields.length > 0 ? (
                <>
                <List
                    {...props}
                    perPage={100}
                    title={project?.projectname}
                    sort={{ field: 'id', order: 'ASC' }}
                    filters={<CBListFilter fields={taskFilterFields} disabledfield={'projectid'} describe={describe} {...props} />}
                    //filterDefaultValues={{ projectid    : filter?.projectid }}
                    actions={<TaskActions filterFields={taskFilterFields} describe={describe} basePath={props.basePath} resource={props.resource} projectid={project?.id} />}
                    pagination={false}
                    component="div"
                >
                    <TaskListContent resource={resource} redirectUrl={`/${resource}?filter=${JSON.stringify({ projectid: project?.id})}`} />
                </List> 
                </>
                ) : (
                    <Loading />
                )
                }
            </>
        }
        </>
    );
};

const useActionStyles = makeStyles(theme => ({
    createButton: {
        marginLeft: theme.spacing(2),
    },
}));
const TaskActions = (props: any) => {
    const classes = useActionStyles();
    const translate = useTranslate();

    return (
        <TopToolbar>
            <CBListFilter context="button" fields={props.filterFields} disabledfield={'projectid'} describe={props.describe} />
            <ExportButton />
            <Button component={Link} to={`/${props.resource}/create/?projectid=${props.projectid}`} color='primary' variant='contained' label={translate('New Task')} className={classes.createButton}>
                <AddIcon />
            </Button>
        </TopToolbar>
    );
};

