import React from "react";
import Box from '@material-ui/core/Box';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';


const Pagination = (props: any) => {

    const paginate = (pageNumber: number, direction: number) => {
        let newOffset = direction >= 0 ? (pageNumber * props.perPage) : props.offset - props.perPage;
        newOffset = newOffset >= 0 ? newOffset : 0;
        props.setOffset(newOffset);
        props.setPage(pageNumber + direction);
    };

    return (
        <Box py={1} display="flex">
            <IconButton color="primary"  onClick={() => paginate(props?.page, -1)} disabled={ props?.page <= 1 || props?.isLoading } aria-label="Next">
                <NavigateBeforeIcon />
            </IconButton>
            <Box mt={1.9} fontWeight="bold"> { props?.isLoading ? <CircularProgress color="primary" size={24} /> : props?.page } </Box>
            <IconButton color="primary" onClick={() => paginate(props?.page, 1)}  disabled={ props?.isLoading } aria-label="Next">
                <NavigateNextIcon />
            </IconButton>
        </Box>
    )
}

export default Pagination;