import React from 'react';
import { useHistory } from 'react-router-dom';
import { EditButton, ShowButton, DeleteButton, Button } from 'react-admin';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
//import Button from '@material-ui/core/Button';



const RowAction = ({ basePath, record, resource, modPermission }) => {
	const [anchorEl, setAnchorEl] = React.useState(null);
  	const open = Boolean(anchorEl);
	  const history = useHistory();

	const handleClick = (event) => {
	setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
	setAnchorEl(null);
	};

    return (
		<>
			<IconButton
				aria-label="more"
				aria-controls="rowMenu"
				aria-haspopup="true"
				onClick={handleClick}
			>
				<MoreVertIcon />
			</IconButton>
			<Menu
				id="rowMenu"
				anchorEl={anchorEl}
				keepMounted
				open={open}
				onClose={handleClose}
			>
				<List component="nav" disablePadding={false}>
					<ListItem><ShowButton basePath={basePath} record={record} style={{textTransform: 'none'}} /></ListItem>
					{modPermission && modPermission.update ? 
						 <ListItem><EditButton basePath={basePath} record={record} style={{textTransform: 'none', marginTop: '-10px'}} /> </ListItem>
						: null}
					{resource && resource === 'Project' &&
						<ListItem style={{ marginTop: '-10px' }}>
							<Button
								label={<span style={{ marginLeft: '-5px' }}>{'Tasks'}</span>}
								color="primary"
								onClick={() => history.push(`${'ProjectTask'}?filter=${JSON.stringify({ projectid: record?.id})}`)}
								style={{textTransform: 'none'}}
								startIcon={<AssignmentTurnedInIcon />}
								>
							</Button>
						</ListItem>
					}
					{modPermission && modPermission.delete ? 
						 <ListItem><DeleteButton basePath={basePath} record={record} style={{textTransform: 'none', marginTop: '-10px'}} /> </ListItem>
						: null}
				</List>
			</Menu>
		</>
	)
};

export default RowAction;