import React, { useState } from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import MenuIcon from '@material-ui/icons/Menu';
import { IconSettings, Icon } from '@salesforce/design-system-react';
import clsx from "clsx";
import {
  MenuItemLink,
  ReduxState,
  Loading,
  useTranslate,
} from "react-admin";
import SubMenu from "./SubMenu";
import { AppState } from "../types";
import Box from "@material-ui/core/Box";
import useMediaQuery from '@material-ui/core/useMediaQuery';


const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 0,
    marginBottom: 0,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    '& .MuiCircularProgress-root': {
        width: '25px !important',
        height: '25px !important',
    },
  },
  open: {
    width: 200,
  },
  closed: {
    width: 55,
  },
  menuTop: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  menuLeft: {
    display: 'block',
    marginTop: '100px',
  },
  menuRight: {
    display: 'block',
    marginTop: '100px',
  },
}));

const AppMenu = ({ dense = false, isLoadingMenu=false }: any) => {
  const sidebarIsOpen = useSelector(
    (state: ReduxState) => state.admin.ui.sidebarOpen
  );
  const menuPosition = useSelector((state: AppState) => state.menuPosition);
  useSelector((state: AppState) => state.theme); // force rerender on theme change
  useSelector((state: AppState) => state.menuPosition); // force rerender on menu position change
  const classes = useStyles();
  const [anchorElIndex, setAnchorElIndex] = useState("");
  const [anchorParentElIndex, setAnchorParentElIndex] = useState("");
  const [open, setOpen] = useState(false);
  const [open2ndLevel, setOpen2ndLevel] = useState(false);
  const isMedium = useMediaQuery((theme: any) => theme.breakpoints.up('md'));
  const translate = useTranslate();
  const menuItemsList = useSelector((state: AppState) => state.appMenu);



  const handleToggle = (parentMenuIndex: any, menuIndex: any) => {
    if (!parentMenuIndex) {
      setOpen(!open);
    } else {
      setOpen2ndLevel(!open2ndLevel);
    }
    setAnchorParentElIndex(parentMenuIndex);
    setAnchorElIndex(menuIndex);
  };

  const MenuItemIcon = ({icon}: {icon: any}) => {
    if(!icon) return <MenuIcon />;
    return(
      <IconSettings iconPath="/icons" style={{minWidth: '40px'}}>
         <div className={`${icon?.containerClass}`}>
            <Icon
              category={icon?.library}
              name={icon?.icon}
              className={`${icon.class}`}
              size="small"
              style={{width: '1.2rem', height: '1.2rem'}}
            />
          </div>
      </IconSettings>
    )
  }

  const CBMenuItemLink = ({menuItem} : {menuItem: any}) => {
    if(menuItem.type === 'url') {
      return (
        <ul className="MuiList-root MuiList-padding" role="menu" tabIndex={-1}>
          <li>
            <a tabIndex={0} role={'menuitem'} aria-disabled={false} href={menuItem.name} target="_blank" rel="noreferrer" className="MuiButtonBase-root MuiListItem-root MuiMenuItem-root RaMenuItemLink-root MuiMenuItem-gutters MuiListItem-gutters MuiListItem-button">
              {menuItem.icon && 
                <MenuItemIcon icon={menuItem.icon} />
              }
              <span style={{marginLeft: '4px'}}>{menuItem.label}</span>
            </a>
          </li>
        </ul>
      )
    }

    return (
      <MenuItemLink
        key={menuItem.name}
        to={{
          pathname: `/${menuItem.name}`,
          state: { _scrollToTop: true },
        }}
        primaryText={menuItem.label}
        leftIcon={<MenuItemIcon icon={menuItem.icon} />}
        dense={dense}
        sidebarIsOpen={sidebarIsOpen}
        onClick={() => menuPosition === 'top' ? handleToggle("", '') : null }
      />
    )
  }

  return (
    <div
      className={clsx(classes.root, {
        [classes.open]: sidebarIsOpen && menuPosition !== 'top',
        [classes.closed]: !sidebarIsOpen,
        [classes.menuTop]: isMedium && menuPosition === 'top',
        [classes.menuLeft]: isMedium && menuPosition === 'left',
        [classes.menuRight]: isMedium && menuPosition === 'right',
      })}
    >

      <Box ml={-0.3}> 
          <MenuItemLink
            key={'home'}
            to={{
              pathname: '/',
              state: { _scrollToTop: true },
            }}
            exact={true}
            primaryText={translate('Dashboard')}
            leftIcon={<MenuIcon />}
            dense={dense}
            sidebarIsOpen={sidebarIsOpen}
          />
      </Box>
      {isLoadingMenu && menuPosition !== 'top' && <Loading loadingPrimary={""} loadingSecondary={""} />}
      {!isLoadingMenu && (
        <>
          {menuItemsList.map((menu: any, menuIndex: number) => {
            return (
              <Box>
                {menu.menuItems.length <= 0 ? (
                  <CBMenuItemLink key={menuIndex} menuItem={menu} />
                ) : (
                  <SubMenu
                    key={menu.name + menuIndex + "submenu"}
                    handleToggle={() => handleToggle("", menu.menuId)}
                    isOpen={
                      open &&
                      (menu.menuId === anchorParentElIndex ||
                        menu.menuId === anchorElIndex)
                    }
                    name={menu.label}
                    icon={<MenuItemIcon icon={menu.icon} />}
                    dense={dense}
                  >
                    {menu.menuItems.map((menuItem2: any, index2: number) => {
                      return (
                        <>
                          {menuItem2 &&
                          menuItem2.menuItems &&
                          menuItem2.menuItems.length <= 0 ? (
                            <CBMenuItemLink key={index2} menuItem={menuItem2} />
                          ) : (
                            <Box ml={2.2}>
                              <SubMenu
                                key={menuItem2.name + index2 + "submenu"}
                                handleToggle={() =>
                                  handleToggle(
                                    menuItem2.parentMenu,
                                    menuItem2.menuId
                                  )
                                }
                                isOpen={
                                  open2ndLevel &&
                                  menuItem2.menuId === anchorElIndex
                                    ? true
                                    : false
                                }
                                name={menuItem2.label}
                                icon={<MenuItemIcon icon={menuItem2.icon} />}
                                dense={dense}
                              >
                                {menuItem2.menuItems.map(
                                  (menuItem3: any, index3: number) => {
                                    return (
                                      <CBMenuItemLink key={index3} menuItem={menuItem3} />
                                    );
                                  }
                                )}
                              </SubMenu>
                            </Box>
                          )}
                        </>
                      );
                    })}
                  </SubMenu>
                )}
              </Box>
            );
          })}
        </>
      )}
    </div>
  );
};

export default AppMenu;
