/* eslint-disable import/no-anonymous-default-export */
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { LayoutProps, Notification, setSidebarVisibility } from 'react-admin';
import AppBar from './AppBar';
import AppMenu from './Menu';
import { AppState } from '../types';
import Auth from '../Auth';
import {
    makeStyles,
} from '@material-ui/core/styles';
import { getMenu } from "../utils/lib";
import { prepareMenu } from "../utils/Helpers";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { changeAppMenu } from '../app-reducers/actions';


const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        zIndex: 1,
        minHeight: '100vh',
        backgroundColor: theme.palette.background.default,
        position: 'relative',
    },
    appFrame: {
        display: 'flex',
        flexDirection: 'column',
        overflowX: 'auto',
    },
    contentWithSidebar: {
        display: 'flex',
        flexGrow: 1,
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 2,
        marginTop: '100px',
        padding: theme.spacing(4),
        paddingTop: 0,
    },
}));


export default (props: LayoutProps) => {
    
    const classes = useStyles();
    const dispatch = useDispatch();
    const open = useSelector((state: any) => state.admin.ui.sidebarOpen);
    const menuPosition = useSelector((state: AppState) => state.menuPosition);
    const [isLoadingMenu, setIsLoadingMenu] = useState(false);
    const isMedium = useMediaQuery((theme: any) => theme.breakpoints.up('md'));
    

    useEffect(() => {
        dispatch(setSidebarVisibility(true));
        setIsLoadingMenu(true);
        getMenu()
          .then((userMenu) => {
            const preparedUserMenu: any = prepareMenu(userMenu);
            dispatch(changeAppMenu(preparedUserMenu));
          })
          .catch((err) => {
            console.log("Something went wrong!");
          })
          .finally(() => {
            setIsLoadingMenu(false);
          });
    }, [dispatch]);


    return (
        <Auth>
            <div className={classes.root}>
                <div className={classes.appFrame}>
                    <AppBar isLoadingMenu={isLoadingMenu} title={props.title} open={open} logout={props.logout} />
                    <main className={classes.contentWithSidebar}>
                        { isMedium && menuPosition === 'left' &&
                            <AppMenu isLoadingMenu={isLoadingMenu} logout={props.logout} hasDashboard={!!props.dashboard} {...props} />
                        }
                        <div className={classes.content}>
                            {props.children}
                        </div>
                        { isMedium && menuPosition === 'right' &&
                            <AppMenu isLoadingMenu={isLoadingMenu} logout={props.logout} hasDashboard={!!props.dashboard} {...props} />
                        }
                    </main>
                    <Notification />
                </div>
            </div>
        </Auth>
    )
};
