import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import {Link} from 'react-router-dom';
import Typography from "@material-ui/core/Typography";

const useStyle = makeStyles(() => ({
    customFieldLink: {
       textDecoration: 'none !important',
    },
}));

const CustomFieldLink = (propsParam: {resource: any, record: any, linkField: string  }) => {

    const classes = useStyle(); 

    return (
        <Typography variant="inherit" className={classes.customFieldLink} component={Link} to={`/${propsParam.resource}/${propsParam.record?.id}/show`} color="primary">
            {propsParam.record ? propsParam.record[`${propsParam.linkField}`] : ''}
        </Typography>
    )
}

export default CustomFieldLink;