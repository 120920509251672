import React, { useState, useEffect } from 'react';
import { List, useListContext, Loading, useTranslate } from 'react-admin';
import CbRecordDetail from './cbRecordDetail';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRight from '@material-ui/icons/ChevronRight';
import { withStyles } from '@material-ui/core/styles';
import {Link} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AppState } from '../types';
import { authProvider } from '../authProvider';
import CbCreateRelatedRecordDrawer from './CbCreateRelatedRecordDrawer';
import DocumentPreviewModal from './DocumentPreview/DocumentPreviewModal';
import { getListColumns } from '../utils/lib';
import { TABLE_AUTH } from '../local-db';
import { getDataFromLocalDb } from '../utils/Helpers';


const Accordion = withStyles({
    root: {
      boxShadow: 'none',
      background: 'inherit',
      borderBottom: '1px solid #eee',
      padding: '1px 0',
      border: 'none',
      '&:before': {
        display: 'none',
      },
      '&$expanded': {
        margin: '0',
      },
    },
    expanded: {},
  })(MuiAccordion);
  
  const AccordionSummary = withStyles({
    root: {
        marginBottom: -8,
        padding: '0 15px',
        minHeight: 15,
        '&$expanded': {
            minHeight: 56,
        },
    },
    content: {
      '&$expanded': {
        margin: '12px 0',
      },
    },
    expanded: {},
  })(MuiAccordionSummary);
  
  const AccordionDetails = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
      '& .list-page': {
        width: '100%',
      },
    },
  }))(MuiAccordionDetails);


const pagesize = 3;

export const CbRelatedList = ({id, describe, basePath, relatedModule, relatedParentModule, moduleRelationType } : {id: string, describe: any, basePath: string, relatedModule: any, relatedParentModule: string, moduleRelationType: string }) => {
    const resource = relatedModule?.name ?? '';
    let realResourceName = resource;
    if(realResourceName === 'Project'){
        realResourceName = 'Projects';
    }
    const translate = useTranslate();
    const theme = useSelector((state: AppState) => state.theme);
    const [title, setTitle] = useState<string>(`${resource} (${0})`);
    const linkfields = describe[resource]?.filterFields?.linkfields ?? [];
    const [expanded, setExpanded] = useState<any>('');
    const [ modPermissions, setModPermissions ] = useState<any>(null);
    const [open, toggleDrawer] = useState(false);
    const [ fields, setFields ] = useState<any[]>([]);

	const defaultSearchquery: any = {};
	defaultSearchquery[relatedModule?.relatedfield] = id;
	defaultSearchquery['relatedModule'] = relatedParentModule;
	defaultSearchquery['moduleRelationType'] = moduleRelationType;
	defaultSearchquery['joinCondition'] = 'AND';
    const [docPreviewOpen, setDocPreviewOpen] = useState<boolean>(false);
    const [ clickedItem, setClickedItem ] = useState<any>({});
    const [user, setUser] = useState({});
    

    useEffect(() => {
        getDataFromLocalDb(TABLE_AUTH.tableName).then((result) => {
            setUser(result?.user??{});
        });
        authProvider.getModulePermission(resource).then((result: any) => {
            setModPermissions(result);
        });

        getListColumns(relatedParentModule).then((result) => { 
            let relatedListColumnNames: any[] = [];
            let modFields = describe[resource]?.filterFields?.fields ?? [];
            let realResourceName = resource;
            if(realResourceName === 'Project'){
                realResourceName = 'Projects';
            }
            if(describe[resource] && describe[resource].ListFieldsName){
                for (const key in describe[resource].ListFieldsName) {
                    if (Object.hasOwnProperty.call(describe[resource].ListFieldsName, key)) {
                        relatedListColumnNames.push(describe[resource].ListFieldsName[key]);
                    }
                }
                // eslint-disable-next-line array-callback-return
                let relatedFields = modFields.filter((field: any) => {
                    if(relatedListColumnNames.includes(field.name)) return field;
                })
                setFields(relatedFields);
            } else {
                const relModFilterFields = describe[realResourceName]?.filterFields?.fields ?? [];
                const relModFields = describe[realResourceName]?.fields ?? [];
                const relModFilters = [];
                // eslint-disable-next-line array-callback-return
                let relfields = relModFields.filter((field: any) => {
                    if(relModFilterFields.includes(field.name)) {
                        relModFilters.push(field);
                        return field;
                    };
                })
                if(relfields.length > 0){
                    setFields(relfields);
                } else {
                    setFields([]);
                }
            }
        }).catch(() => {
            console.log(translate('translations.somethingWentWrong'));
            const relModFilterFields = describe[realResourceName]?.filterFields?.fields ?? [];
                const relModFields = describe[realResourceName]?.fields ?? [];
                const relModFilters = [];
                // eslint-disable-next-line array-callback-return
                let relfields = relModFields.filter((field: any) => {
                    if(relModFilterFields.includes(field.name)) {
                        relModFilters.push(field);
                        return field;
                    };
                })
                if(relfields.length > 0){
                    setFields(relfields);
                } else {
                    setFields([]);
                }
        })
        
    }, [describe, realResourceName, relatedParentModule, resource, translate])

    const handleChange = (panel: any) => (event: any, newExpanded: any) => {
        setExpanded(newExpanded ? panel : false);
    };

    const handleDrawer = () => {
        toggleDrawer(!open);
    }

    const handleDocPreviewModalOpen = (record: any = null) => {
        setClickedItem(record);
        setDocPreviewOpen(true);
    };


    return (
        <Box mt={2} >
            {/**
             * TransitionProps={{ unmountOnExit: true }} 
             * Records will be fetched when the accordion is opened, which causes difficulties on 
             * knowing the total number of records. But in case of performance, this feature is good. 
             */}
            <DocumentPreviewModal record={clickedItem} title={clickedItem?.notes_title} open={docPreviewOpen} setOpen={setDocPreviewOpen} />
            <Accordion square TransitionProps={{ unmountOnExit: false }} expanded={expanded === resource} onChange={handleChange(resource)}>
                <Box display={'flex'}>
                    <Box mt={-2} style={{width: '100%'}} >
                        <AccordionSummary>
                            { expanded ? <ExpandMoreIcon color="primary" /> : <ChevronRight color="primary" /> } 
                            <Typography>
                                {title}
                            </Typography>
                        </AccordionSummary>
                    </Box>
                    <Box mx={2} mt={-1} >
                        {modPermissions && modPermissions.create && 
                             <Button
                             variant="text"
                             color={theme === 'dark' ? 'default' : 'primary'}
                             onClick={() => handleDrawer()}
                         >
                             {`+ ${translate('translations.addAction')}`}
                         </Button>
                        }
                    </Box>
                </Box>
                
                <AccordionDetails>
                    <List
                        basePath={basePath}
                        resource={resource}
                        filterDefaultValues={defaultSearchquery}
                        title={<></>}
                        perPage={pagesize}
                        actions={false}
                        pagination={false}
                        component="div"
                        >
                            <ListContent id={id} handleDocPreviewModalOpen={handleDocPreviewModalOpen} moduleRelationType={moduleRelationType} relatedfield={relatedModule?.relatedfield} fields={fields} describe={describe} linkfields={linkfields} resource={resource} relatedParentModule={relatedParentModule} setTitle={setTitle} />
                    </List>
                </AccordionDetails>
            </Accordion>
            {   open && 
			    <CbCreateRelatedRecordDrawer user={user} handleDocPreviewModalOpen={handleDocPreviewModalOpen} relatedFields={fields} linkfields={linkfields} relatedModDetails={relatedModule} open={open} toggleRelatedModuleDrawer={handleDrawer} describe={describe} relatedFieldValue={id} modPermissions={modPermissions} />
		    }
        </Box>
    )
}

const ListContent = (props:any) => {
    const translate = useTranslate();
    const { data, ids, total, loaded } = useListContext<any>();
    
    props.setTitle(`${props.resource} (${total ?? 0})`);

    return (
        <Box>
            {!loaded && 
                <Loading loadingPrimary={""} loadingSecondary={""} />
            }
            {ids.map((id: any, index: number) => 
                <CbRecordDetail key={index} handleDocPreviewModalOpen={props.handleDocPreviewModalOpen} record={data[id]} fields={props.fields} describe={props.describe} linkfields={props.linkfields} resource={props.resource} />
            )}
            {total > pagesize &&
                <Typography variant="inherit" component={Link} to={`/${props.resource}?relmod=${props.relatedParentModule}&relfield=${props.relatedfield}&relfieldval=${props.id}&reltype=${props.moduleRelationType}`} color="primary" style={{textDecoration: 'none'}}>
                    {translate('translations.viewAll')}
                </Typography>
            }
        </Box>
    )


}