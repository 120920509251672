//import React, { useState, useEffect, useContext } from 'react';
import React, { useState, useEffect } from 'react';
import corebosUtils from '../component/corebosUtils/corebosUtils'
import {
    useMutation,
    Identifier,
    useListContext,
    RecordMap,
    // DataProviderContext,
} from 'react-admin';
import { Box } from '@material-ui/core';
import { DragDropContext, OnDragEndResponder } from 'react-beautiful-dnd';
import isEqual from 'lodash/isEqual';

import { TaskColumn } from './TaskColumn';
import { status } from './status';

interface TasksByColumn {
    [status: string]: Identifier[];
}

const initialTasks: TasksByColumn = status.reduce(
    (obj, val) => ({ ...obj, [val]: [] }),
    {}
);

const getTasksByColumn = (
    ids: Identifier[],
    data: RecordMap<any>
): TasksByColumn => {
    const columns = ids.reduce(
        (acc, id) => {
            acc[data[id]?.projecttaskstatus]?.push(id);
            return acc;
        },
        status.reduce((obj, val) => ({ ...obj, [val]: [] }), {} as any)
    );
    status.forEach(val => {
        columns[val] = columns[val].sort(
            (a: Identifier, b: Identifier) => data[a].id - data[b].id
        );
    });
    return columns;
};

export const TaskListContent = (props:any) => {
    const {
        data,
        ids,
        loaded,
        page,
        perPage,
        currentSort,
        filterValues,
    } = useListContext<any>();

    const [tasks, setTasks] = useState<TasksByColumn>(
        loaded ? getTasksByColumn(ids, data) : initialTasks
    );

    // const dataProvider = useContext(DataProviderContext);

    const [refresh] = useMutation({
        resource: 'ProjectTask',
        type: 'getList',
        payload: {
            pagination: { page, perPage },
            sort: currentSort,
            filter: filterValues,
        },
    });

    useEffect(() => {
        /* eslint-disable react-hooks/exhaustive-deps */
        if (!loaded) return;
        const newTasks = getTasksByColumn(ids, data);
        if (isEqual(tasks, newTasks)) {
            return;
        }
        setTasks(newTasks);
    }, [data, ids, loaded]); 

    if (!loaded) return null;

    const onDragEnd: OnDragEndResponder = async result => {
        const { destination, source, draggableId } = result;
        if (!destination) {
            return;
        }

        if (
            destination.droppableId === source.droppableId &&
            destination.index === source.index
        ) {
            return;
        }

        if (source.droppableId === destination.droppableId) {
            return;
            /* const column = Array.from(tasks[source.droppableId]); 
            const sourceTask = data[column[source.index]];
            const destinationTask = data[column[destination.index]];

            column.splice(source.index, 1);
            column.splice(destination.index, 0, Number(draggableId));
            setTasks({
                ...tasks,
                [source.droppableId]: column,
            });

            const { data: columnTasks } = await dataProvider.getList('ProjectTask', {
                sort: { field: 'id', order: 'ASC' },
                pagination: { page: 1, perPage: 100 },
                filter: {},
            });

            if (source.index > destination.index) {
                await Promise.all([
                    ...columnTasks
                        .filter(
                            task =>
                                task.index >= destinationTask.index &&
                                task.index < sourceTask.index
                        )
                ]);
            } else {
                await Promise.all([
                    ...columnTasks
                        .filter(
                            task =>
                                task.index <= destinationTask.index &&
                                task.index > sourceTask.index
                        )
                        .map(task =>
                            dataProvider.update('ProjectTask', {
                                id: task.id,
                                data: { index: task.index - 1 },
                                previousData: task,
                            })
                        ),
                ]);
            } */
        } else {

            const sourceColumn = Array.from(tasks[source.droppableId]);
            const destinationColumn = Array.from(
                tasks[destination.droppableId]
            );
            const sourceTask = data[sourceColumn[source.index]];
            //const destinationTask = data[destinationColumn[destination.index]];

            sourceColumn.splice(source.index, 1);
            destinationColumn.splice(destination.index, 0, draggableId);
            setTasks({
                ...tasks,
                [source.droppableId]: sourceColumn,
                [destination.droppableId]: destinationColumn,
            });

            sourceTask.projecttaskstatus = destination?.droppableId;
            corebosUtils.cbUpdate('ProjectTask', sourceTask).then(() => {
                refresh();
            });
           
        }
    };

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Box display="flex">
                {status.map(val => (
                    <TaskColumn
                        status={val}
                        taskIds={tasks[val]}
                        data={data}
                        key={val}
                        resource={props?.resource}
                    />
                ))}
            </Box>
        </DragDropContext>
    );
};
