import React, {useEffect} from 'react'
import * as cbconn from 'corebos-ws-lib/WSClientm';
import mermaid from 'mermaid';
import {useForm} from 'react-final-form';

export const PFPushAlong = ({pfid, ctxid, isDetailView}) => { 
    const form = useForm();

    function renderGraph(value, pfidParam) {
        let output = document.getElementById('pfpaoutputgraph' + pfidParam);
        try {
            output.innerHTML = '';
            mermaid.parse(value);
            mermaid.render('theGraph' + pfidParam, value, function (svgCode) {
                output.innerHTML = svgCode;
            });
            window.dispatchEvent(window.coreBOS.PFStateEvent);
        } catch (err) {
            console.error(err);
        }
    }

    const getGraph = (pfidParam, ctxidParam, _isDetailView) => {
        cbconn.doInvoke('PushAlongFlow', {pflowid: pfidParam, contextid: ctxidParam}, 'GET')
            .then(
                data => {
                    renderGraph(data.graph, pfidParam);
                    window[data.functionname] = (tostate, _forrecord, _askifsure) => {
                        if (_isDetailView) {
                            let element = {
                                'id': data.record
                            };
                            element[data.fieldname] = tostate;
                            cbconn.doRevise(data.module, element).then(() => getGraph(pfid, ctxid));
                        } else {
                            form.change(data.fieldname, tostate);
                        }
                    }
                },
                reason => {
                    console.warn('fail to read graph: '+reason);
                }
            );
    };

    useEffect(() => {
        /* eslint-disable react-hooks/exhaustive-deps */
        if (window.coreBOS === undefined) {
            window.coreBOS = {};
        }
        window.coreBOS.PFStateEvent = new CustomEvent('PFStateUpdated', {processflow: pfid});
        mermaid.initialize({startOnLoad: true, securityLevel: 'loose'});
        getGraph(pfid, ctxid, isDetailView);
    }, [pfid, ctxid, isDetailView]);

    return (
        <div id={'pfpaoutputgraph' + pfid}/>
    );
};