import React, { useState } from 'react';
import { useRedirect, EditButton, Button, Notification, useTranslate } from 'react-admin';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Draggable } from 'react-beautiful-dnd';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
//import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { fade } from '@material-ui/core/styles/colorManipulator';
//import Swal from 'sweetalert2';
import { removeTags } from './utils';
import { TASK_DESCRIPTION_LENGTH } from './constant';
import QuickCreateModal from '../component/QuickCreateModal';
import AccessTimeIcon from '@material-ui/icons/AccessTime';


const useStyles = makeStyles(theme => ({
    root: {
        minWidth: '320px',
        marginBottom: theme.spacing(1),
    },
    cardHeader: {
        padding: theme.spacing(1),
        display: 'flex',
    },
    cardContent: {
        padding: theme.spacing(1),
        display: 'flex',
    },
    cardText: {
        marginLeft: theme.spacing(1),
    },
    taskActionButton: {
        marginLeft: theme.spacing(2),
        textTransform: 'none',
    },
    deleteButton: {
        marginLeft: theme.spacing(2),
        textTransform: 'none',
        color: theme.palette.error.main,
        '&:hover': {
          backgroundColor: fade(theme.palette.error.main, 0.12),
          '@media (hover: none)': {
            backgroundColor: 'transparent'
          }
        }
    }
}));

export const TaskCard = ({ task, index, resource }: { task: any; index: number, resource: string }) => {
    
    const classes = useStyles();
    const redirect = useRedirect();
    //const notify = useNotify();
    //const [isLoading, setIsLoading] = useState(false);
    //const [deleteOne] = useDelete(resource);
    const [quickCreateOpen, setQuickCreateOpen] = useState<boolean>(false);
    const translate = useTranslate();



    if (!task) return null;

    const handleClick = () => {
        redirect(`/${resource}/${task.id}/show`);
    };

    /* const onSuccess = (message: string) => {
        notify(message, 'success');
        window.location.reload();
    };

    const onFailure = (error: string) => {
        notify(error, 'error');
    } */

   /*  const handleDelete = (record: any) => {
        Swal.fire({
            title: 'Are you sure you want to delete this?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          }).then((result) => {
            if (result.isConfirmed) {
                setIsLoading(true);
                deleteOne(
                    { payload: { id: record.id, data: record } },
                    {
                        onSuccess: () => {
                            setIsLoading(false);
                            onSuccess('Record deleted');
                        },
                        onFailure: ({ error } : {error: any}) => {
                            setIsLoading(false);
                            onFailure(error.message);
                        }
                    }
                );
            }
        })
    } */

    const handleQuickCreateModalOpen = () => {
        setQuickCreateOpen(true);
    };


    return (
        <>
        <QuickCreateModal resource='Timecontrol' relatedMod={resource} defaultValues={{relatedto: task.id}} title={task.projecttaskname} open={quickCreateOpen} setOpen={setQuickCreateOpen} />
        <Draggable draggableId={String(task.id)} index={index}>
            {(provided, snapshot) => (
                <div
                    className={classes.root}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    ref={provided.innerRef}
                >
                    <Card
                        style={{
                            opacity: snapshot.isDragging ? 0.9 : 1,
                            transform: snapshot.isDragging
                                ? 'rotate(-2deg)'
                                : '',
                        }}
                        className={classes.root}
                        elevation={snapshot.isDragging ? 3 : 1}
                    >
                       

                        <Box py={1} display={'flex'}>
                            <Box justifyContent="start">
                                <IconButton color='primary' onClick={ () => handleQuickCreateModalOpen()} title={translate('Time Control')}  >
                                    <AccessTimeIcon fontSize="large" />
                                </IconButton>
                            </Box>
                            <Box mt={1}>
                                <Typography>{task.projecttaskname}</Typography>
                                <Typography>{task.projecttask_no}</Typography>
                            </Box>
                        </Box>
                        <CardContent>
                            <Typography variant="body2" color="textSecondary" component="p">
                               {/*  <RichTextField source="description" record={task} /> */}
                               { removeTags(task.description.slice(0, TASK_DESCRIPTION_LENGTH)) } { task.description.length > TASK_DESCRIPTION_LENGTH ? '...' : '' }
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Typography variant="body2" color="textSecondary" component="span"> {task.projecttaskprogress} </Typography>
                            <Button className={classes.taskActionButton} onClick={handleClick} label={'View'} >
                                <VisibilityIcon />
                            </Button>
                            <EditButton
                                to={`/${resource}/${task.id}`}
                                basePath={resource}
                                label="Edit"
                                record={JSON.parse(JSON.stringify(task))}
                                className={classes.taskActionButton}
                            />
                            {/* <Button className={classes.deleteButton} onClick={() => handleDelete(task)} disabled={isLoading} label={'Delete'} >
                                <DeleteIcon />
                            </Button> */}
                        </CardActions>
                    </Card>
                </div>
            )}
        </Draggable>
        <Notification />
        </>
    );
};
