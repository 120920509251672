import React, { useState, useEffect} from 'react';
import { Show, TabbedShowLayout, TabbedShowLayoutTabs, Tab, Notification, useTranslate, Button, FunctionField } from 'react-admin';
import cbUtils from '../corebosUtils/corebosUtils';
import { getDataFromLocalDb } from '../../utils/Helpers';
import { TABLE_DESCRIBE } from '../../local-db';
import { 
	Box, 
	Grid, 
	Typography,
	useMediaQuery,
	ExpansionPanel,
	ExpansionPanelSummary,
	ExpansionPanelDetails,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useParams } from 'react-router-dom';
import { getRelatedModules } from '../../utils/lib';
import { IMAGEINFO_FULL_PATH } from '../../constant';
import Title from '../Title';
import { CbRelatedList } from '../cbRelatedList';
import { useSelector } from 'react-redux';
import DocumentPreviewModal from '../DocumentPreview/DocumentPreviewModal';



export const CbShowTabGuesser = props => {
	let resource = props.resource;
	const [describe, setDescribe] = useState({});
	const [blocks, setBlocks] = useState([]);
	const modPermission = props?.options?.permission[0]?.permissions??{};
	const [relatedModules, setRelatedModules] = useState([]);
	const { id } = useParams();
	const translate = useTranslate();
	const isMedium = useMediaQuery(theme => theme.breakpoints.up('md'));
	const [ expanded, setExpanded ] = useState(null);
	const [ labelField, setLabelField ] = useState(props.label??'');
	const theme = useSelector((state) => state.theme);
	const [docPreviewOpen, setDocPreviewOpen] = useState(false);
	const [ clickedItem, setClickedItem ] = useState({});



	useEffect(() => {
		getDataFromLocalDb(TABLE_DESCRIBE.tableName).then((result) => {
			setDescribe(result);
			let bfields = [];
			let mfields = result[resource]?.fields;
			for (let f = 0; f<mfields.length; f++) {
				if (! mfields[f].block) {
					continue;
				}
				let bidx = bfields.findIndex((element) => element.id === mfields[f].block.blockid);
				if (bidx===-1) {
					bfields.push({
						id: mfields[f].block.blockid,
						sequence: mfields[f].block.blocksequence,
						label: mfields[f].block.blocklabel,
						name: mfields[f].block.blockname,
						fields: []
					});
					bidx = bfields.findIndex((element) => element.id === mfields[f].block.blockid);
				}
				bfields[bidx].fields.push(mfields[f]);
			}

			setBlocks(bfields);
			if(!props.label){
				const labelFields = result[resource]?.labelFields;
				if(labelFields){
					let aLabelField = labelFields.split(',');
					aLabelField = aLabelField && aLabelField[0] ? aLabelField[0] : '';
					setLabelField(aLabelField);
				}
			}
			if(!props.hiderelmod){
				getRelatedModules(resource).then((rMods) => { 
					if(rMods && rMods.blocks){
						const rModules = Object.values(rMods.blocks);
						const relatedMods = [];
						rModules.forEach(rMod => {
							const describeRelatedMod = result[rMod.loadfrom];
							if(describeRelatedMod){
								describeRelatedMod.relatedfield = rMod.relatedfield;
								describeRelatedMod.moduleRelationType = rMod.relatedfield ? '1:N' : 'N:N';
								relatedMods.push(describeRelatedMod);
							}
						});
						setRelatedModules(relatedMods);
					}
				}).catch(() => {
					console.log(translate('translations.somethingWentWrong'));
				})
			}
		});
	}, [props.hiderelmod, props.label, resource, translate])


	const handleChange = panel => (event, expanded) => {
		setExpanded(expanded ? panel : false);
	};

	const handleDocPreviewModalOpen = (record = null) => {
		setClickedItem(record);
        setDocPreviewOpen(true);
    };

	return (
		<Box px={0.5}  >
		<DocumentPreviewModal record={clickedItem} title={clickedItem?.notes_title} open={docPreviewOpen} setOpen={setDocPreviewOpen} />

		<Grid container spacing={4} >
			<Grid item xs={12} md={ relatedModules && relatedModules.length > 0 ? 8 : 12} style={{overflowY: 'auto', minHeight: '100vh', background: theme === 'dark' ? 'inherit' : '#f5f8fa'}}>
				<Box className='raPageContent'>
					<Show
						{...props}
						title={ <Title resource={resource} label={props.label} showTitle={props.showTitle} record={props.record} linkField={labelField} /> }
						hasEdit={modPermission.update}
						hasCreate={modPermission.create}
						hasList={modPermission.list}
						hasShow={modPermission.list}
						>
						<>
						{
							isMedium 
							?	<TabbedShowLayout syncWithLocation={false} tabs={<TabbedShowLayoutTabs variant="scrollable" scrollButtons="auto" {...props} />} >
								{
									blocks.map((block, bidx) => {
										return (
											<Tab key={'fbrefblk'+bidx} label={block.name}>
												<Box style={{width: '100%'}} py={3}>
													<Grid container spacing={2} >
													{
														block.fields.map((field, idx) => {
															if(field.uitype === '28'){
																
																return(
																	<Grid key={idx} item xs={12} md={6}> 
																		<Typography variant="subtitle2"> {translate('File')} </Typography>
																		<FunctionField label={translate('File')} render={(aRecord) => {
																			return (
																				<>
																					{ aRecord.filetype.split('/')[0] === 'image' 
																						? <img 
																								key={idx}
																								src={aRecord?._downloadurl} 
																								alt={aRecord?.notes_title} 
																								style={{width: '50%', margin: 'auto', cursor: 'pointer' }} 
																								onClick={ () => handleDocPreviewModalOpen(aRecord)}
																							/>
																						: 	<Button 
																								key={idx}
																								color='primary' 
																								label={aRecord?.notes_title} 
																								onClick={ () => handleDocPreviewModalOpen(aRecord)}
																							/>
																					}
																				</>
																				
																			)}
																		} />
																	</Grid>
																)

															} else {
																return (
																	<Grid key={idx} item xs={12} md={6}> 
																		<Typography variant="subtitle2"> {field.label} </Typography>
																		{cbUtils.field2DisplayElement(field, resource, describe)} 
																	</Grid>
																)
															}
														})
													}
													</Grid>
												</Box>
											</Tab>
										)
									})
								}
								</TabbedShowLayout>
							:	<>
								{
									blocks.map((block, index) => {
										return (
											<ExpansionPanel key={block.name+index} expanded={expanded === block.name} onChange={handleChange(block.name)}>
												<ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
													<Typography> {block.name} </Typography>
												</ExpansionPanelSummary>
												<ExpansionPanelDetails>
													<Grid container spacing={2} >
														{
															block.fields.map((field, idx) => {
																if(field.uitype === '69'){
																	const newField = JSON.parse(JSON.stringify(field));
																	newField.name = field.name+IMAGEINFO_FULL_PATH;
																	field = newField;
																}
																const cbField = cbUtils.field2DisplayElement(field, resource, describe);
																return cbField ? <Grid key={idx} item xs={12} md={6}> <Typography variant="subtitle2"> {field.label} </Typography>{cbField} </Grid> : <></>;
															})
														}
													</Grid>
												</ExpansionPanelDetails>
											</ExpansionPanel>
										)
										})
								}
								</>
						}
						</>

					</Show>
				</Box>
			</Grid>
			{ relatedModules.length > 0 &&
				<>
					<Grid item xs={12} md={4} style={{borderLeft: '1px solid #eee', height: '100vh', overflowY: 'auto', padding: '0'}}>
						<Box mt={4}>
							{relatedModules.map((relatedModule, index) => {
								return (
									<Box key={index}>
										<CbRelatedList id={id} describe={describe} basePath={relatedModule?.name} relatedModule={relatedModule} relatedParentModule={resource} moduleRelationType={relatedModule.moduleRelationType} />
									</Box>
								);
							})}
						</Box>
					</Grid>
					
				</>
			}
		</Grid>
		<Notification />
		</Box>
	)
};
