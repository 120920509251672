import * as React from 'react';
import { useState, useEffect } from 'react';
import {
    useDelete,
    useNotify,
    Notification,
    TextField,
    ReferenceField,
    DateField,
    //Button,
    //SaveContextProvider,
    /* ReferenceManyField,
    Pagination, */
} from 'react-admin';
import EditIcon from '@material-ui/icons/Edit';
import ReplyIcon from '@material-ui/icons/Reply';
//import ModeCommentIcon from '@material-ui/icons/ModeComment';
import CircularProgress from '@material-ui/core/CircularProgress';
import TrashIcon from '@material-ui/icons/Delete';
import FormatQuoteIcon from '@material-ui/icons/FormatQuote';
import { COMMENT_REPLY_LIMIT, REPLY_TEXT_LENGTH } from '../constant';
import { useHistory, useLocation } from "react-router-dom";


import {
    Box,
    IconButton,
} from '@material-ui/core';
import { useCommentStyle } from './useCommentStyle';
import { CommentContent } from './CommentContent';
import CommentForm from "./CommentForm";
import { removeTags } from '../utils';
import { doQuery } from '../lib';


export const CommentItem = ({ comment, projectTask, setReloadTriggerID, resource, handleDocPreviewModalOpen }:{ comment:any, projectTask: any, setReloadTriggerID: any, resource: string, handleDocPreviewModalOpen: any }) => {

    const [isEditingMode, setEditingMode] = useState(false);
    const [isReplyingMode, setIsReplyingMode] = useState(false);

    const notify = useNotify();
    const classes = useCommentStyle();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [clickedCommentId, setClickedCommentId] = useState('');
    const [commentReplies, setCommentReplies] = useState<any[]>([]);
    const [isLoadingReplies, setIsLoadingReplies] = useState<boolean>(false);
    //const [replyOffset, setReplyOffset] = useState<number>(0);
    //const [parentCommentId, setParentCommentId] = useState<string>('');
    const [replyingComment, setReplyingComment] = useState<any>({});
    const [editingComment, setEditingComment] = useState<any>({});
    const [deleteOne] = useDelete();
    const history = useHistory();
    let location = useLocation();
    const url = new URLSearchParams(location.search);


   /*  const loadCommentReplies = async (parentCommentID: string) => {
        const isTheSameParentCommentID = parentCommentID === parentCommentId;
        let newOffet = isTheSameParentCommentID ? replyOffset + (COMMENT_REPLY_LIMIT-1) : 0;
        setReplyOffset(newOffet);
        setParentCommentId(parentCommentID);
        setIsLoadingReplies(true);
        const result = await doQuery(`SELECT * FROM ${resource} WHERE parent_comments = ${parentCommentID} ORDER BY createdtime DESC LIMIT ${newOffet}, ${COMMENT_REPLY_LIMIT}`);
        const replies = isTheSameParentCommentID ? [...result??[], ...commentReplies] : result??[];
        setCommentReplies(replies);
        setIsLoadingReplies(false);
    } */

    useEffect(() => {
        setIsLoadingReplies(true);
        doQuery(`SELECT * FROM ${resource} WHERE parent_comments = ${comment.id} ORDER BY createdtime DESC LIMIT ${0}, ${COMMENT_REPLY_LIMIT}`).then((result) => {
            setCommentReplies(result);
            setIsLoadingReplies(false);
        }).catch(() => {
            setIsLoadingReplies(false);
        })
    }, [comment.id, resource])

    useEffect(() => {
        if(clickedCommentId){
            const clickedCommentEl:any = document.getElementById(clickedCommentId);
            clickedCommentEl.scrollIntoView({ behavior: 'smooth', block: 'start'});
        }else{
            const aUrl = new URLSearchParams(location.search);
            const commentId: any = aUrl.get('commentId');
            if(commentId){
                history.push({
                    pathname: location.pathname,
                    search: "?" + new URLSearchParams({commentId: commentId}).toString()
                });
                setTimeout(() => {
                    const clickedCommentEl:any = document.getElementById(commentId);
                    if(clickedCommentEl){
                        clickedCommentEl.scrollIntoView({ behavior: 'smooth', block: 'start'});
                    }
                }, 3000);
            }
        }
    }, [clickedCommentId, history, location.pathname, location.search])

    const deleteComment = (_comment: any) => {
        setIsLoading(true);
        deleteOne(resource, _comment.id, _comment, {
            //mutationMode: 'undoable',
            onSuccess: (result: any) => {
                setReloadTriggerID(new Date());
                setIsLoading(false);
            },
            onFailure: (error) => {
                setIsLoading(false);
                notify(error, 'error');
            },
        });
    }

    const handleEnterEditMode = (aComment: any) => {
        setEditingComment(aComment);
        setEditingMode(true);
    };

    const handleEnterReplyingMode = (commentReplied: any) => {
        //loadCommentReplies(commentReplied.id);
        setReplyingComment(commentReplied);
        setIsReplyingMode(true);
    };

    const handleCancelReply = () => {
        setIsReplyingMode(false);
        setReplyingComment('');
    };

    const handleCancelEdit = () => {
        setEditingComment(null);
        setEditingMode(false);
    };

    /* const LoadMoreBtn = ({parentCommentID, label='Load more'}:{parentCommentID:string, label: string}) => {
        return (
            <Button 
            label={label}
            color='primary'
            size='small'
            style={{textTransform: 'none'}}
            onClick={ () => loadCommentReplies(parentCommentID) }
            />
        )
    } */

    const CommentFooter = ({parentCommentID, aComment}:{parentCommentID:string, aComment: any}) => {
        return (
            <Box display="flex">
                <Box mr={1}>
                    <DateField
                        source="createdtime"
                        record={comment}
                        variant="body2"
                        showTime
                        locales="en"
                        options={{
                            dateStyle: 'full',
                            timeStyle: 'short',
                        }}
                    />
                </Box>
                {parentCommentID &&
                     <Box mr={1} mt={-0.5}>
                        <IconButton size="small" title="Reply" onClick={() => handleEnterReplyingMode(aComment)} >
                            { isLoadingReplies ? <CircularProgress color="primary" size={20} /> : <ReplyIcon />}
                        </IconButton>
                    </Box>
                }
                <Box mr={1} mt={-0.5}><IconButton size="small" title="Edit" onClick={() => handleEnterEditMode(aComment)} ><EditIcon /></IconButton></Box>
                <Box mr={1} mt={-0.5}><IconButton size="small" title="Delete" onClick={() => deleteComment(aComment)} > { isLoading ?  <CircularProgress color="primary" size={20} /> : <TrashIcon /> } </IconButton></Box>
                {/* <Box mr={1} mt={-0.5}>
                    <IconButton size="small" title="Load replies" onClick={() => loadCommentReplies(parentCommentID)} > 
                        { isLoadingReplies && (parentCommentID === parentCommentId) ? <CircularProgress color="primary" size={20} /> : <ModeCommentIcon />}
                    </IconButton>
                </Box> */}
            </Box>
        )
    }

    return (
        <Box py={1} mt={-2}>
            
            {isEditingMode ? (  
                <CommentForm  isNewRecord={false} comment={editingComment} handleCancel={handleCancelEdit} replyingComment={replyingComment} projectTask={projectTask} setReloadTriggerID={setReloadTriggerID} resource={resource} />
            ) : (
                <Box mt={1}>
                     {!comment.parent_comments &&
                        <Box id={comment.id}>
                             <Box>
                                <ReferenceField
                                source={'creator'}
                                record={comment}
                                reference="Contacts"
                                resource={resource}
                                link="show"
                                >
                                    <TextField
                                        source={'user_name'}
                                        component="div"
                                    />
                                </ReferenceField> 
                            </Box>
                             <Box mt={0.5} className={ url.get('commentId') === comment.id ? classes.sharedComment : classes.content}><CommentContent handleDocPreviewModalOpen={handleDocPreviewModalOpen} aComment={comment} setClickedCommentId={setClickedCommentId} resource={resource}  /></Box>
                             <CommentFooter parentCommentID={comment.id} aComment={comment} />
                             <>
                                {isReplyingMode &&
                                    <Box ml={4} mt={2}>
                                        <em><FormatQuoteIcon /><small>{ removeTags(replyingComment?.commentcontent)?.slice(0, REPLY_TEXT_LENGTH) } </small> {'...' } <FormatQuoteIcon /></em>
                                        <CommentForm isNewRecord={true} comment={null} handleCancel={handleCancelReply} replyingComment={replyingComment} projectTask={projectTask} setReloadTriggerID={setReloadTriggerID} resource={resource} />
                                    </Box>
                                }
                    
                                {
                                    commentReplies.map((reply: any) => {
                                        return (
                                            <Box ml={4} my={2}>
                                                <Box>
                                                    <ReferenceField
                                                    source={'creator'}
                                                    record={reply}
                                                    reference="Contacts"
                                                    resource={resource}
                                                    link="show"
                                                    >
                                                        <TextField
                                                            source={'user_name'}
                                                            component="div"
                                                        />
                                                    </ReferenceField> 
                                                </Box>
                                                <Box mt={0.5} className={classes.content}><CommentContent handleDocPreviewModalOpen={handleDocPreviewModalOpen} aComment={reply} setClickedCommentId={setClickedCommentId} resource={resource}  /></Box>
                                                <CommentFooter parentCommentID={''} aComment={reply} />
                                            </Box>
                                        )
                                    })
                                }
                                {/* {commentReplies.length > 0 &&
                                    <Box width={'25%'} mx={'auto'}>
                                        <LoadMoreBtn parentCommentID={comment.id} label={'Load more replies'} />
                                    </Box>
                                } */}
                            </>
                        </Box>
                    }

                </Box>
            )}
            <Notification />
        </Box>
    )

}