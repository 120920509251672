import * as React from 'react';
import { useState, useEffect } from 'react';
import {
    ShowBase,
    useRecordContext,
    useRedirect,
    RichTextField,
    Button,
    useDelete,
    useNotify,
    Notification,
    EditButton,
    useRefresh,
    Loading,
    useTranslate,
    Title,
} from 'react-admin';
import {
    Box,
    Typography,
    Divider,
    Grid,
    Card,
    CardContent,
} from '@material-ui/core';


import { UserAvatar } from './User';
import DeleteIcon from '@material-ui/icons/Delete';
import { useCommentStyle } from './comment/useCommentStyle';
import { CommentList } from './comment/CommentList';
import CommentForm from './comment/CommentForm';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import QuickCreateModal from '../component/QuickCreateModal';
import { getDataFromLocalDb } from '../utils/Helpers';
import { TABLE_DESCRIBE } from '../local-db';
import cbUtils from '../component/corebosUtils/corebosUtils';


export const TaskShow = (props: any) => {
    //const classes = useCommentStyle();
    const [ reloadTriggerID, setReloadTriggerID ]  = useState<any>(null);
    const refresh = useRefresh();

    useEffect(() => {
        refresh();
    }, [reloadTriggerID, refresh])

    return (
        <ShowBase resource={props?.resource} basePath={props?.basePath} id={props?.id}>
            <TaskShowContent {...props} reloadTriggerID={reloadTriggerID} setReloadTriggerID={setReloadTriggerID} resource={props?.resource} />
        </ShowBase>
    );
};

const TaskShowContent = (props:any) => {
    const classes = useCommentStyle();
    const record = useRecordContext();
    const [isLoading, setIsLoading] = useState(false);
    const notify = useNotify();
    const redirect = useRedirect();
    const [deleteOne] = useDelete(props?.resource);
    const resourceComment = 'ModComments';
    const modPermission = props?.options?.permission[0]?.permissions??{};
    const translate = useTranslate();
    const [quickCreateOpen, setQuickCreateOpen] = useState<boolean>(false);
    const [ filterFields, setFilterFields ] = useState<any[]>([]);
    const [ describe, setDescribe ] = useState<any>({});
    const [ labelField, setLabelField ] = useState<any>('');


    useEffect(() => {
		getDataFromLocalDb(TABLE_DESCRIBE.tableName).then((result) => {
            setDescribe(result);
			const modFields = result[props.resource]?.fields ?? [];
			const fFields = result[props.resource]?.filterFields?.fields ?? [];
            let lfield = result[props.resource].labelFields.split(',');
            lfield = lfield[0] ?? '';
            setLabelField(lfield);
            let filters = [];
            for (let index = 0; index < modFields.length; index++) {
                if(fFields.includes(modFields[index].name)){
                    filters.push(modFields[index]);
                }
            }
            setFilterFields(filters);
		});
	}, [props.resource])




    const handleDelete = (record: any) => {
        setIsLoading(true);
        deleteOne(
            { payload: { id: record.id, data: record } },
            {
                onSuccess: () => {
                    setIsLoading(false);
                    notify('Record deleted', 'success');
                    redirect(`${props.resource}`);
                    //window.location.reload();
                    
                },
                onFailure: ({ error } : {error: any}) => {
                    setIsLoading(false);
                    notify(error.message, 'error');
                }
            }
        );
    }

    const handleQuickCreateModalOpen = () => {
        setQuickCreateOpen(true);
    };



    if (!record) return <Loading />;

    const title = record.projecttaskname;

    return (
        <>
            <Title title={title} />
            <QuickCreateModal resource='Timecontrol' relatedMod={props?.resource} defaultValues={{relatedto: record.id}} title={title} open={quickCreateOpen} setOpen={setQuickCreateOpen} />
            <Grid container spacing={2}>
                <Grid item xs={12} md={9} >
                    <Box mt={2}>
                        <Card elevation={2} style={{width: 'auto', padding: '16px'}}>
                            <CardContent>
                                <Box display={'flex'} mb={3}>
                                    <Box justifyContent="start">
                                        <Typography variant="h5">{record[labelField]}</Typography>
                                        <Typography variant="body2">{record.projecttask_no}</Typography>
                                    </Box>
                                    <span style={{flex: 1}} />
                                    <Box justifyContent="end" px={2}>
                                        <Button color='primary' onClick={ () => handleQuickCreateModalOpen()} label={translate('Time Control')} >
                                            <AccessTimeIcon />
                                        </Button>
                                        {modPermission && modPermission.update && 
                                            <EditButton
                                                to={`/${props.resource}/${record.id}`}
                                                basePath={props.resource}
                                                label="Edit"
                                                record={JSON.parse(JSON.stringify(record))}
                                                className={classes.taskActionButton}
                                            />
                                        }
                                        {modPermission && modPermission.delete && 
                                            <Button className={classes.deleteButton} onClick={() => handleDelete(record)} disabled={isLoading} label={'Delete'} >
                                                <DeleteIcon />
                                            </Button>
                                        }
                                    </Box>
                                </Box> 
                            
                                <Grid container spacing={2}>
                                    { filterFields.map((field: any) => {
                                            let referenceCustomLink = '';
                                            if(field.name === 'projectid' ){
                                                referenceCustomLink = `/${'ProjectTask'}?filter=${JSON.stringify({ projectid: record?.projectid})}`;
                                            }
                                            return (
                                                <>
                                                    { field.name !== labelField && 
                                                        <Grid item xs={12} md={2} >
                                                            <Box>
                                                                <Typography color="textSecondary" variant="body2">
                                                                    {field.label}
                                                                </Typography>
                                                                { cbUtils.field2DisplayElement(field, props.resource, describe, '', referenceCustomLink)  }
                                                            </Box>
                                                        </Grid>
                                                    }
                                                </>
                                            )
                                        })
                                    }
                                </Grid>
                                <Box mt={2} style={{ whiteSpace: 'pre-line' }}>
                                    <Typography color="textSecondary" variant="body2">
                                        Description
                                    </Typography>
                                    <Typography>
                                        <RichTextField source="description" />
                                    </Typography>
                                </Box>
                            </CardContent>
                        </Card>
                        <Box>
                            <Divider />
                            <Box>
                                <CommentForm handleCancel={null} replyingComment={null} comment={null} isNewRecord={true} projectTask={record} setReloadTriggerID={props.setReloadTriggerID} resource={'ModComments'} />
                            </Box>
                            <Box>
                                <CommentList resource={resourceComment} reloadTriggerID={props.reloadTriggerID} setReloadTriggerID={props.setReloadTriggerID} projectTask={record}  />
                            </Box> 
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Box mt={2}>
                        <Card elevation={2} >
                            <CardContent>
                                <Box
                                    width={50}
                                    mx={'auto'}
                                    display="flex"
                                    flexDirection="column"
                                    alignItems="center"
                                >
                                    <UserAvatar size={'large'} record={record} referenceCustomLink={`/Contacts/${record.assigned_user_id}/show`} heading={'Assignee:'} title={record.assigned_user_idename?.reference} sourceField={'user_name'} refSourceField={'assigned_user_id'} resource={props?.resource}  />
                                </Box>
                            </CardContent>
                        </Card>
                    </Box>
                </Grid>
            </Grid>
            
            <Notification />
        </>
    );
};




