import * as React from 'react';
import { useState, useEffect } from 'react';
import { Box, Card, Typography } from '@material-ui/core';
import * as cbconn from 'corebos-ws-lib/WSClientm';
import { ModMenuIcon } from '../utils/Helpers';
import { useSelector } from 'react-redux';

const RelatedModCard = ({parentMod, parentId, relatedMod, handleRelatedModule } : {parentMod: string, parentId: string, relatedMod: any, handleRelatedModule: any}) => {

	const [ relatedRecordsCount, setRelatedRecordsCount ] = useState<number>(0);
	const appMenu = useSelector((state: any) => state.appMenu);

	useEffect(() => {
		cbconn.doGetRelatedRecords(parentId, parentMod, relatedMod.name, {}).then((result: any) => {
			setRelatedRecordsCount(result?.records?.length);
		})
	}, [parentId, parentMod, relatedMod.name])
	

    return (
		<Card 
			onClick={() => handleRelatedModule(relatedMod, parentId)} 
			elevation={4} 
			className={'relatedModCard'}
			>
			<Box textAlign={'center'}>
				<ModMenuIcon menu={appMenu} moduleName={relatedMod.name} IconContainerStyle={{maxWidth: '30px'}} IconStyle={{width: '3rem', height: '3rem'}} />
				<Typography variant="subtitle2" style={{marginTop: '10px'}}> {relatedMod.label} </Typography>
				<Typography variant="subtitle2"> { relatedRecordsCount } </Typography>
			</Box>
		</Card>
    )
}

export default RelatedModCard;