import * as React from 'react';
import { useEffect, useState, useCallback } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getDataFromLocalDb } from '../utils/Helpers';
import { TABLE_DESCRIBE } from '../local-db';
import cbUtils from './corebosUtils/corebosUtils';
import { Create, SimpleForm, Toolbar, SaveButton, useCreate, useNotify } from 'react-admin';
import { authProvider } from '../authProvider';



const QuickCreateModal = ({defaultValues, resource, relatedMod, title, open, setOpen } : { defaultValues: any, resource: string, relatedMod: string, title: any, open: boolean, setOpen: any }) => {

	const [ describe, setDescribe ] = useState<any>({});
	const [ fields, setFields ] = useState<any[]>([]);
	const [ modPermission,  setModPermission ] = useState<any>({});
	const [ create ] = useCreate();
	const notify = useNotify();
	const [isLoading, setIsLoading] = useState(false);


	useEffect(() => {
		getDataFromLocalDb(TABLE_DESCRIBE.tableName).then((result) => {
			setDescribe(result);
			let modFields = result[resource]?.fields ?? [];
			modFields = modFields.filter((field: any) => field.quickcreate === true);
			//modFields = modFields.filter((field: any) => field);
			setFields(modFields);
		});
		authProvider.getModulePermission(resource).then((result: any) => {
			setModPermission(result);
		})
	}, [resource])

	const handleClose = () => {
		setOpen(false);
	};

	const saveRecord = useCallback(
		async (values) => {
			setIsLoading(true);
			create(resource, values, {
				onSuccess: () => {
					setOpen(false);
				},
				onFailure: () => {
					setIsLoading(false);
					notify('something went wrong', 'error');
				},
			});
		},
		[create, resource, setOpen, notify],
	);

	const CustomToolbar = (props: any) => {
		return (
			<Toolbar {...props}>
				{modPermission && modPermission.create && 
					<SaveButton
						label="Save"
						icon = { isLoading ? <CircularProgress color={isLoading ? 'primary' : 'secondary' } size={24} /> : <></>}
						disabled={props.pristine || isLoading}
						size="small"
						{...props}
					/>
				}
			</Toolbar>
		);
	}

    return (
		<div>
			<Dialog
				open={open}
				onClose={handleClose}
				scroll={'paper'}
				aria-labelledby="quick-create"
				aria-describedby="quick-create-modal"
			>
				<DialogTitle id="quick-create">{title}</DialogTitle>
				<DialogContent dividers >
					<Create
						resource={resource}
						basePath={`/${resource}`}
						title={<></>}
						>
						<SimpleForm save={saveRecord} initialValues={defaultValues} toolbar={<CustomToolbar />} >
							{
								fields.map((field, idx) => {
									return cbUtils.field2InputElement(field, resource, {fullWidth: true}, describe, '', relatedMod);
								})
							}
						</SimpleForm>
					</Create>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="primary">
						Cancel
					</Button>
				</DialogActions>
			</Dialog>
		</div>
    )
}

export default QuickCreateModal;