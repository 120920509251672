import Box from "@material-ui/core/Box/Box";
import { Button, RichTextField } from "react-admin";
import { useHistory, useLocation } from "react-router-dom";
import { COMMENT_SHOW_LENGTH } from "../constant";
import { useEffect, useState } from "react";
import { doQuery } from '../lib';
import { CommentAttachment } from '../components/CommentAttachment';


export const CommentContent = ({aComment, setClickedCommentId, resource, handleDocPreviewModalOpen} : { aComment: any, setClickedCommentId: any, resource: string, handleDocPreviewModalOpen: any }) => {

    const history = useHistory();
    let location = useLocation();
    const [ comment, setComment ] = useState<any>(aComment);
    const [ commentContent, setCommentContent ] = useState<string>(aComment.commentcontent);
    const [ showMore, setShowMore ] = useState<boolean>(false);
    const [fileList, setFileList] = useState<any[]>([])

    useEffect(() => {
        const query = `SELECT * FROM Documents WHERE Related.${resource}= ${comment.id}`;
        doQuery(query).then(result => {
            setFileList(result);
        })
    }, [resource, comment.id]) 

    useEffect(() => {
        if(showMore){
            const comnt = JSON.parse(JSON.stringify(aComment));
            comnt.commentcontent = commentContent;
            setComment(comnt);
        }else{
            if(commentContent.length > COMMENT_SHOW_LENGTH){
                const comnt = JSON.parse(JSON.stringify(aComment));
                comnt.commentcontent = `<span>${commentContent.slice(0, COMMENT_SHOW_LENGTH)}</span> <span id=${aComment.id+'dots'} >...</span>`;
                setComment(comnt);
            }
        }
    }, [showMore, commentContent, aComment]);
    
    const handleReadMore = (readMore: boolean) => {

        if(readMore){
            setShowMore(false);
            setCommentContent(aComment.commentcontent);
        }else{
            setShowMore(true);
        }

    }

    

    const handleCommentClick = (commentId: string) => {
        setClickedCommentId(commentId);
        history.push({
            pathname: location.pathname,
            search: "?" + new URLSearchParams({commentId: commentId}).toString()
        })
    }

    return (
        <>
        {comment && 
            <Box py={3} px={2} mx={-2} style={{cursor: 'pointer', borderRadius: '14px'}}>
                <Box onClick={() => handleCommentClick(comment.id)}>
                    <RichTextField 
                    source="commentcontent" 
                    record={comment}
                />
                </Box>
                {commentContent.length > COMMENT_SHOW_LENGTH &&
                    <Button label={ showMore ? 'Read less' : 'Read more'} color='primary' size='small' style={{textTransform: 'none', margin: '-15px auto auto -10px'}} id={comment.id+'readMoreBtn'} onClick={ () => handleReadMore(showMore) }/>
                }
               
               <Box>
                    <CommentAttachment fileList={fileList} handleDocPreviewModalOpen={handleDocPreviewModalOpen} />
                </Box>
            </Box>
        }
        </>
    )
}