import italianMessages from 'ra-language-italian';

const ra = italianMessages.ra;

ra.page.dashboard = 'Dashboard';
ra.page.empty = 'Vuota';
ra.page.invite = 'Invitare';
ra.action.export = 'Esportare';

const it = {
    ra,
    dashboard: {
        searchInputPlaceholder: "Ricerca...",
    },
    translations: {
        somethingWentWrong: "Qualcosa è andato storto",
        all: "Tutto",
        dataUpdated: "Dati aggiornati",
        menuPosition: "Posizione del menu",
        left: "Sinistra",
        right: "Destra",
        top: "Superiore",
        upload: "Caricamento",
        unsupportedFileType: "Tipo di file non supportato",
        relateAction: "Relazionare",
        selectAction: "Selezionare",
        backAction: "Di ritorno",
        theme: {
            name: 'Tema',
            light: 'Ligera',
            dark: 'Oscura',
        },
        configuration: "Configurazione",
        profile: "Profilo",
        viewAll: "Mostra tutto",
        addAction: "Inserisci",
        associateNew: "Associato Nuovo",
        associateExisting: "Associato esistente",
        cancelAction: "Annulla",
        closeAction: "chiudere",
        associateThisRecord: "Associa nuovo o esistente a questo record",
    },
    "incorrect response: INVALID_SESSIONID: Session Identifier provided is Invalid": 'Sessione non valida',
    "Theme Setting": "Impostazione del tema",
    "Theme Color": "Theme Color",
    "Menu Position": "Colore del tema",
    "Top": "Superiore",
    "Left": "Sinistra",
    "Right": "Destra",
    "Position": "Posizione",
    "Time Control": "Controllo del tempo",
    "Dashboard": "Pannello",
    "Cancel": "Annulla",
    "File": "File",
    "Search": "Ricerca",
    "View All": "Mostra Tutto",
    "Search for": "Cercare",
};

export default it;
