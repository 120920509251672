import Box from "@material-ui/core/Box";
import { CommentItem } from "./CommentItem";
import { useState, useEffect } from 'react';
import { doQuery } from '../lib';
import Pagination from "../components/Pagination";
import { useNotify, Notification } from "react-admin";
import DocumentPreviewModal from '../../component/DocumentPreview/DocumentPreviewModal';

export const CommentList = ({projectTask, setReloadTriggerID, reloadTriggerID, resource } : {projectTask: any, setReloadTriggerID: any, reloadTriggerID: any, resource: string}) => {
    
    //const { data, ids } = useListContext<any>();
    const notify: any = useNotify();
    const [ comments, setComments ] = useState<any[]>([]);
    const [offset, setOffset] = useState(0);
    const [page, setPage] = useState(1);
    const [perPage] = useState(10);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [docPreviewOpen, setDocPreviewOpen] = useState<boolean>(false);
    const [ clickedItem, setClickedItem ] = useState<any>({});

    useEffect(() => {
        const q = `SELECT * FROM ModComments WHERE related_to=${projectTask?.id} ORDER BY createdtime DESC LIMIT ${offset}, ${perPage}`;
        setIsLoading(true);
        doQuery(q).then(result => {
            setComments([]);
            setComments(result);
        }).catch(function (error) {
            notify(error, 'error');
        })
        .finally(() => {
            setIsLoading(false);
        })
    }, [notify, offset, perPage, projectTask?.id, reloadTriggerID])

    const handleDocPreviewModalOpen = (record: any = null) => {
        setClickedItem(record);
        setDocPreviewOpen(true);
    };

    return (
        <Box px={4} py={3} style={{ border: '1px solid #ddd' }}>
            <DocumentPreviewModal record={clickedItem} title={clickedItem?.notes_title} open={docPreviewOpen} setOpen={setDocPreviewOpen} />
            {comments.map((comment, index) => (
                <Box mb={2}>
                    <CommentItem handleDocPreviewModalOpen={handleDocPreviewModalOpen} resource={resource} comment={comment} key={index} projectTask={projectTask} setReloadTriggerID={setReloadTriggerID} />
                </Box>
            ))}
            <Box width={'25%'} mx={'auto'} my={2} textAlign="center">
                <Pagination offset={offset} setOffset={setOffset} page={page} setPage={setPage} perPage={perPage} isLoading={isLoading}  />
            </Box>
            <Notification />
        </Box>
    )
}
